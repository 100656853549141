import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../http";

type DeviceDetails = {
    id: number,
    ident: string,
    hardware_version: string,
    software_version: string,
    type: string,
    status: string
}

type DeviceDetailsState = {
    device: DeviceDetails | null,
    loading: boolean,
    error: string | null
}

const initialState: DeviceDetailsState = {
    device: null,
    loading: false,
    error: null
}

export const getDeviceDetails = createAsyncThunk<DeviceDetails, number, { rejectValue: string }>(
    'deviceDetails/getDeviceDetails',
    async (idDevice, { rejectWithValue }) => {
        console.log("getDeviceDetails " + idDevice);

        const response = await api.get(`/devices/${idDevice}`);

        if (response.status !== 200) {
            return rejectWithValue('Request ERROR');
        }

        return response.data;
    })

const deviceDetailsSlice = createSlice({
    name: 'deviceDetails',
    initialState,
    reducers: {
        clearDeviceDetailsState(state) {
            state.device = initialState.device;
            console.log("clearDeviceDetailsState");

        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDeviceDetails.pending, (state) => {
                //state.device = action.payload.device;
                state.loading = true;
                state.error = null;
            })
            .addCase(getDeviceDetails.fulfilled, (state, action) => {
                state.device = action.payload;
                state.loading = false;
                state.error = null;
            })
    }
})

export const { clearDeviceDetailsState } = deviceDetailsSlice.actions;

export const deviceDetailsReducer = deviceDetailsSlice.reducer;
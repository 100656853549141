import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError, isAxiosError } from "axios";
import api from "../../http";
import { ErrorResponse } from "../../types/error-response.type";
import { LanguageType } from "../../types/language.type";

type UsecaseItem = {
    id: number;
    title: LanguageType;
    description: LanguageType;
    text: LanguageType;
    img: string | null;
    img_min: string;
    tags: string[];
}

type UsecaseItemState = {
    usecaseItem: UsecaseItem;
    loading: boolean;
    error: string | null;
}

type GetUsecaseItemResponse = UsecaseItem;

const usecaseItemInit: UsecaseItem = {
    id: 0,
    title: { "en": "" },
    description: {},
    text: {},
    img: null,
    img_min: "",
    tags: [],
}
const initialState: UsecaseItemState = {
    usecaseItem: usecaseItemInit,
    loading: false,
    error: null
}

export const getUsecaseItem = createAsyncThunk<GetUsecaseItemResponse, number, { rejectValue: string }>(
    'usecaseItem/getUsecaseItem',
    async (idUsecase, { rejectWithValue }) => {
        if (Number(idUsecase) === 0) {
            return usecaseItemInit;
        }
        const response = await api.get(`/articles/${idUsecase}?style=full`);

        if (response.status !== 200) {
            return rejectWithValue('Request ERROR');
        }

        return response.data;
    })

export const saveUsecaseItem = createAsyncThunk<GetUsecaseItemResponse, UsecaseItem>(
    'usecaseItem/saveUsecaseItem',
    async (usecaseItem, { rejectWithValue }) => {
        type UsecaseSaveType = UsecaseItem & {
            chapter: string;
        }
        const usecaseSaveDraft: UsecaseSaveType = { ...usecaseItem, chapter: "usecases" };
        const { id, ...usecaseSave } = usecaseSaveDraft;
        // console.log(usecaseSave);

        try {
            const response = usecaseItem.id === 0
                ? await api.post(`/articles`, usecaseSave)
                : await api.patch(`/articles/${usecaseItem.id}`, usecaseSave);

            if (response.status !== 200) {
                return rejectWithValue('Request ERROR');
            }

            return response.data;
        } catch (e) {
            if (isAxiosError(e)) {
                const err = e as AxiosError;
                return rejectWithValue(err.response?.data as ErrorResponse);
            }
            return rejectWithValue("Request ERROR");
        }
    })

export const deleteUsecaseItem = createAsyncThunk<GetUsecaseItemResponse, number, { rejectValue: string }>(
    'usecaseItem/deleteUsecaseItem',
    async (idUsecase, { rejectWithValue }) => {
        const response = await api.delete(`/articles/${idUsecase}`);

        if (response.status !== 200) {
            return rejectWithValue('Request ERROR');
        }

        return response.data;
    })

export const uploadUsecaseImage = createAsyncThunk
    <GetUsecaseItemResponse, { idUsecase: number, formData: FormData, isMinimal: boolean }, { rejectValue: string }>(
        'usecaseItem/uploadImage',
        async ({ idUsecase, formData, isMinimal }, { rejectWithValue }) => {
            const queryParams = isMinimal ? "?minimal=true" : "";
            const response = await api.post(`/articles/upload_image/${idUsecase}${queryParams}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });

            if (response.status !== 200) {
                return rejectWithValue('Request ERROR');
            }

            return response.data;
        })

export const deleteUsecaseImage = createAsyncThunk
    <GetUsecaseItemResponse, { idUsecase: number, isMinimal: boolean }, { rejectValue: string }>(
        'usecaseItem/deleteImage',
        async ({ idUsecase, isMinimal }, { rejectWithValue }) => {
            const queryParams = isMinimal ? "?minimal=true" : "";
            const response = await api.post(`/articles/delete_image/${idUsecase}${queryParams}`);

            if (response.status !== 200) {
                return rejectWithValue('Request ERROR');
            }

            return response.data;
        })

const usecaseItemSlice = createSlice({
    name: 'usecaseItem',
    initialState,
    reducers: {
        setUsecaseTitle(state, action: PayloadAction<{ lang: string, value: string }>) {
            var usecaseItemNew = state.usecaseItem;
            if (usecaseItemNew) {
                usecaseItemNew.title = usecaseItemNew.title ? usecaseItemNew.title : {};
                usecaseItemNew.title[action.payload.lang as keyof LanguageType] = action.payload.value;
            }
            state.usecaseItem = usecaseItemNew;
        },
        setUsecaseDescription(state, action: PayloadAction<{ lang: string, value: string }>) {
            var usecaseItemNew = state.usecaseItem;
            if (usecaseItemNew) {
                usecaseItemNew.description = usecaseItemNew.description ? usecaseItemNew.description : {};
                usecaseItemNew.description[action.payload.lang as keyof LanguageType] = action.payload.value;
            }
            state.usecaseItem = usecaseItemNew;
        },
        setUsecaseText(state, action: PayloadAction<{ lang: string, value: string }>) {
            var usecaseItemNew = state.usecaseItem;
            if (usecaseItemNew) {
                usecaseItemNew.text = usecaseItemNew.text ? usecaseItemNew.text : {};
                usecaseItemNew.text[action.payload.lang as keyof LanguageType] = action.payload.value;
            }
            state.usecaseItem = usecaseItemNew;
        },
        addUsecaseTag(state, action: PayloadAction<string>) {
            var usecaseItemNew = state.usecaseItem;
            if (usecaseItemNew) {
                usecaseItemNew.tags.push(action.payload);
            }
            state.usecaseItem = usecaseItemNew;
        },
        removeUsecaseTag(state, action: PayloadAction<string>) {
            var usecaseItemNew = state.usecaseItem;
            if (usecaseItemNew) {
                usecaseItemNew.tags = usecaseItemNew.tags.filter(tag => tag !== action.payload);
            }
            state.usecaseItem = usecaseItemNew;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUsecaseItem.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getUsecaseItem.fulfilled, (state, action) => {
                state.loading = false;
                // console.log(action.payload);

                const stateNew = state;
                stateNew.usecaseItem = action.payload ? action.payload : usecaseItemInit;
                state = stateNew;
            })
            .addCase(saveUsecaseItem.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(saveUsecaseItem.rejected, (state, rejectValue) => {
                state.loading = false;
                state.error = null;
                // console.log(rejectValue);
            })
            .addCase(saveUsecaseItem.fulfilled, (state, action) => {
                state.loading = false;
                // console.log(action.payload);
                const stateNew = state;
                stateNew.usecaseItem = action.payload ? action.payload : usecaseItemInit;
                state = stateNew;
            })
            .addCase(deleteUsecaseItem.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteUsecaseItem.rejected, (state, rejectValue) => {
                state.loading = false;
                state.error = null;
            })
            .addCase(deleteUsecaseItem.fulfilled, (state, action) => {
                const stateCur = state;
                state.loading = false;
                state.usecaseItem = usecaseItemInit;
                state = stateCur;
            })
            .addCase(uploadUsecaseImage.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(uploadUsecaseImage.fulfilled, (state, action) => {
                state.loading = false;
                // console.log(action.payload);
                const stateNew = state;
                stateNew.usecaseItem = action.payload ? action.payload : usecaseItemInit;
                state = stateNew;
            })
            .addCase(deleteUsecaseImage.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteUsecaseImage.fulfilled, (state, action) => {
                state.loading = false;
                // console.log(action.payload);
                const stateNew = state;
                stateNew.usecaseItem = action.payload ? action.payload : usecaseItemInit;
                state = stateNew;
            })
    }
})

export type { UsecaseItem };

export const { setUsecaseTitle, setUsecaseDescription, setUsecaseText,
    addUsecaseTag, removeUsecaseTag } = usecaseItemSlice.actions;

export const usecaseItemReducer = usecaseItemSlice.reducer;
import { Container } from "semantic-ui-react"
import UsecasesListPage from "./UsecasesListPage"

export const UsecasesPage: React.FC = () => {

    return (
        <Container style={{ float: "left", paddingLeft: 20 }}>
            <UsecasesListPage />
        </Container>
    )
}
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IActivePage {
    value: string
}

const initialState: IActivePage = {
    value: "dashboard"
}

const activePageSlice = createSlice({
    name: 'activePage',
    initialState,
    reducers: {
        setActivePage(state, action: PayloadAction<string>) {
            state.value = action.payload;
        }
    }
})

export const { setActivePage } = activePageSlice.actions;

export const activePageReducer = activePageSlice.reducer;
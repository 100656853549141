import { Container } from "semantic-ui-react"
import GoodsList from "./GoodsList"

export const GoodsPage: React.FC = () => {

    return (
        // <div><span>asdasads</span></div>
        <Container style={{ float: "left", paddingLeft: 20 }}>
            <GoodsList />
        </Container>
    )
}
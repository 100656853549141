import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, List } from "semantic-ui-react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getTags, getUsecases } from "../../store/slices/UsecasesSlice";

const UsecasesListPage = () => {

    const tagsList = useAppSelector(state => state.usecases.tags);

    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getTags())
    }, [dispatch])

    const handleAddUsecaseClick = () => {
        navigate("0")
    }

    return (
        <div>
            <Button
                style={{ marginLeft: 30 }}
                onClick={handleAddUsecaseClick}>
                {"Add usecase"}
            </Button>
            <List>
                {tagsList.map((tag) => (
                    <List.Item key={tag.title}>
                        <List.Icon name="folder" />
                        <List.Content>
                            <List.Header style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    dispatch(getUsecases({ tag: tag.title }))
                                }}>{tag.title}
                            </List.Header>
                            {
                                tag.usecases ?
                                    tag.usecases.map((usecase) => (
                                        <List.List key={`usecase_${usecase.id}`}>
                                            <List.Item>
                                                <List.Icon name="file" />
                                                <List.Content>
                                                    <List.Header style={{ cursor: 'pointer' }}>
                                                        <Link to={`${usecase.id}`}>{usecase.title}</Link>
                                                    </List.Header>
                                                </List.Content>
                                            </List.Item>
                                        </List.List>
                                    )) : ""
                            }
                        </List.Content>
                    </List.Item>
                ))}
            </List>
        </div>
    );
}

export default UsecasesListPage;
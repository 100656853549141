import { AxiosResponse } from "axios";
import api from "../http";
import { AuthResponse } from "./AuthResponse";

export default class AuthService {

    static async login(login: string, password: string, fingerprint: string): Promise<AxiosResponse<AuthResponse>> {

        return await api.post<AuthResponse>('/login', { login: login, password: password, app_ident: "cloud_app_v1", fingerprint });
    }

    static async refresh(): Promise<AxiosResponse<AuthResponse>> {
        return await api.post<AuthResponse>('/refresh_tokens', { refreshToken: localStorage.getItem("refreshToken") });
    }
}
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { useNavigate, useParams } from "react-router-dom";
import { Button, Checkbox, Dropdown, Image, Input, Modal, Segment, TextArea } from 'semantic-ui-react';
import { useFilePicker } from "use-file-picker";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { GoodItem, LangSpecType, addGoodCategory, addGoodSpec, deleteGoodItem, deleteGoodPhoto, deleteGoodSpec, getGoodItem, removeGoodCategory, saveGoodItem, setGoodDescription, setGoodDescriptionShort, setGoodPhotoMain, setGoodPrice, setGoodSku, setGoodSpecTitle, setGoodSpecValue, setGoodTitle, uploadGoodPhoto } from "../../store/slices/GoodItemSlice";
import { getCategories } from "../../store/slices/GoodsSlice";
import { LanguageType } from "../../types/language.type";

export const GoodItemPage: React.FC = () => {

    const quillModules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image'],
            ['clean']
        ],
    };

    const langOptions = [
        { key: "en", text: "English (US)", value: "en" },
        { key: "es", text: "Spanish", value: "es" },
        { key: "ru", text: "Russian", value: "ru" },
    ]

    const params = useParams();

    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const good = useAppSelector(state => state.goodItem.goodItem);
    const goodState = useAppSelector(state => state.goodItem);
    const categoriesList = useAppSelector(state => state.goods.categories);

    const [curLang, setCurLang] = useState("en");

    const [openError, setOpenError] = useState(false);

    useEffect(() => {
        const idGood = params.idGood as unknown as number;
        dispatch(getGoodItem(idGood));
        dispatch(getCategories());
    }, [dispatch, params]);


    const handleBackClick = () => {
        navigate("/goods");
    }

    const handleDropdownLangChange = (event: any, { value }: any) => {
        // console.log(value);
        setCurLang(value)
    }

    const handleSaveClick = () => {
        if (!good.title.en?.trim()) {
            setOpenError(true);
        } else {
            dispatch(saveGoodItem(good as GoodItem));
            if (good.id === 0) {
                navigate("/goods");
            }
        }
    }

    const handleClearChangesClick = () => {
        if (good?.id && good?.id > 0) {
            dispatch(getGoodItem(good?.id))
        }
    }

    const handleDeleteClick = () => {
        if (good?.id && good?.id > 0) {
            dispatch(deleteGoodItem(good?.id));
            navigate("/goods");
        }
    }

    const handleAddSpecClick = () => {
        dispatch(addGoodSpec(curLang));
    }

    const handleDeleteSpecClick = (num: number) => {
        dispatch(deleteGoodSpec({ num, lang: curLang }));
    }

    const handleDeletePhotoClick = (filePath: string) => {
        dispatch(deleteGoodPhoto({ idGood: good.id, filePath }));
    }

    const handleSetPhotoMainClick = (filePath: string) => {
        dispatch(setGoodPhotoMain({ idGood: good.id, filePath }));
    }

    const [openFileSelector, { }] = useFilePicker({
        // const [openFileSelector, { filesContent, loading, errors, plainFiles, clear }] = useFilePicker({
        multiple: false,
        readAs: 'ArrayBuffer', // availible formats: "Text" | "BinaryString" | "ArrayBuffer" | "DataURL"
        // accept: '.ics,.pdf',
        accept: ['.jpg', '.jpeg', '.tiff', '.bmp', '.png'],
        // limitFilesConfig: { min: 2, max: 3 },
        // minFileSize: 1, // in megabytes
        maxFileSize: 5,
        // readFilesContent: false, // ignores file content

        onFilesSelected: async ({ plainFiles, filesContent, errors }) => {
            var bodyFormData = new FormData();
            bodyFormData.append('file', plainFiles[0]);
            dispatch(uploadGoodPhoto({ idGood: good.id, formData: bodyFormData }))
        },
    });

    return (
        <div>
            <div style={{ marginBottom: 30 }}>
                <Button
                    onClick={handleBackClick}>
                    {"Back"}
                </Button>
            </div>
            <Dropdown style={{ marginBottom: '20px' }}
                placeholder='Select language' selection
                options={langOptions}
                defaultValue={curLang}
                onChange={handleDropdownLangChange}
            />
            <Button
                style={{ marginLeft: 30 }}
                loading={goodState.loading}
                onClick={handleSaveClick}>
                {"Save"}
            </Button>

            <Button
                style={{ marginLeft: 30 }}
                onClick={handleClearChangesClick}>
                {"Clear changes"}
            </Button>

            {good.id > 0 &&
                <Button
                    style={{ marginLeft: 30 }}
                    color="red"
                    onClick={handleDeleteClick}>
                    {"Delete"}
                </Button>
            }

            {/* Title block >>> */}
            <div style={{ marginTop: '30px' }}><h2><b>Title</b></h2></div>
            <Input
                style={{ width: '500px' }}
                placeholder='Title'
                value={good.title && good.title[curLang as keyof LanguageType] ? good.title[curLang as keyof LanguageType] : ""}
                onChange={(e) => dispatch(setGoodTitle({ lang: curLang, value: e.target.value }))}
            />
            {/* Title block <<< */}

            {/* Categories block >>> */}
            <div style={{ marginTop: '30px' }}><h2><b>Categories</b></h2></div>
            <Segment vertical style={{ overflow: 'auto', maxHeight: 200, maxWidth: 150 }}>
                {categoriesList.map((category, num) => (
                    category.title !== "<0>"
                        ? <Checkbox
                            key={num}
                            label={category.name}
                            checked={
                                good?.categories.find(good_category => good_category.title === category.title) ? true : false
                            }
                            onChange={(e, data) => data.checked ? dispatch(addGoodCategory(category.title)) : dispatch(removeGoodCategory(category.title))}
                        />
                        : ""
                ))}
            </Segment>
            {/* Categories block <<< */}

            {/* SKU block >>> */}
            <div style={{ marginTop: '30px' }}><h2><b>SKU</b></h2></div>
            <Input
                placeholder='SKU'
                value={good.sku}
                onChange={(e) => dispatch(setGoodSku(e.target.value))}
            />
            {/* SKU block <<< */}

            {/* Price block >>> */}
            <div style={{ marginTop: '30px' }}><h2><b>Price</b></h2></div>
            <span style={{ marginRight: '10px' }}>USD: </span>
            <Input
                placeholder='USD'
                value={good.price && good.price.USD ? good.price.USD : ""}
                onChange={(e) => {
                    const result: number = Number(e.target.value.replace(/[^0-9]/, ''));
                    // console.log(e.target.value + " " + result);
                    dispatch(setGoodPrice({ currency: "USD", value: result }))
                }
                }
            />
            <span style={{ margin: '0 10px' }}>CAD: </span>
            <Input
                placeholder='CAD'
                value={good.price && good.price.CAD ? good.price.CAD : ""}
                onChange={(e) => {
                    const result: number = Number(e.target.value.replace(/[^0-9]/, ''));
                    dispatch(setGoodPrice({ currency: "CAD", value: result }))
                }
                }
            />
            {/* Price block <<< */}

            {/* Short description block >>> */}
            <div style={{ marginTop: '30px' }}><h2><b>Short Description</b></h2></div>
            <TextArea
                style={{ width: '500px' }}
                rows={3}
                placeholder='Short description'
                value={good?.description_short && good?.description_short[curLang as keyof LanguageType] ? good?.description_short[curLang as keyof LanguageType] : ""}
                onChange={(e) => dispatch(setGoodDescriptionShort({ lang: curLang, value: e.target.value }))}
            />
            {/* Short description block <<< */}

            {/* Description block >>> */}
            <div style={{ marginTop: '30px' }}><h2><b>Description</b></h2></div>
            <div style={{ width: "800px", height: "600px", overflowY: 'auto' }}>
                <ReactQuill
                    style={{ height: "550px" }}
                    theme="snow"
                    value={good?.description && good?.description[curLang as keyof LanguageType] ? good?.description[curLang as keyof LanguageType] : ""}
                    modules={quillModules}
                    onChange={(value) => {
                        dispatch(setGoodDescription({ lang: curLang, value }))
                    }} />
            </div>
            {/* Description block <<< */}

            {/* Specifications block >>> */}
            <div style={{ marginTop: '30px' }}><h2><b>Specifications</b></h2></div>
            <div style={{ width: 1000 }}>
                {good?.specs[curLang as keyof LangSpecType]?.map(
                    (spec, num) => (
                        <div key={num}>
                            <Input
                                style={{ verticalAlign: "top", marginRight: 20 }}
                                placeholder='Specification title'
                                value={spec.title}
                                onChange={(e) => dispatch(setGoodSpecTitle({ num, lang: curLang, title: e.target.value }))}
                            />
                            <TextArea
                                style={{ width: '500px' }}
                                rows={3}
                                placeholder='Specification value'
                                value={spec.value}
                                onChange={(e) => dispatch(setGoodSpecValue({ num, lang: curLang, value: e.target.value }))}
                            />
                            <Button
                                style={{ verticalAlign: "top", marginLeft: 20 }}
                                color="red"
                                onClick={() => handleDeleteSpecClick(num)}>
                                {"Delete"}
                            </Button>
                        </div>
                    )
                )}
            </div>
            <Button
                onClick={handleAddSpecClick}>
                {"Add specification"}
            </Button>
            {/* Specifications block <<< */}

            {/* Photos block >>> */}
            <div style={{ marginTop: '30px' }}><h2><b>Photos</b></h2></div>
            <div style={{ marginTop: '20px' }}><h3><b>Main photo</b></h3></div>
            {good.photos.feature &&
                <div style={{ width: 800, position: "relative" }}>
                    <Image
                        style={{ float: "left" }}
                        src={good.photos.feature}
                        size="medium" />
                    <Button
                        style={{ float: "left", marginLeft: 20 }}
                        color="red"
                        onClick={() => { handleDeletePhotoClick(good.photos.feature) }}>
                        {"Delete"}
                    </Button>
                </div>
            }
            <div style={{ clear: "both", marginTop: '20px', paddingTop: '20px' }}><h3><b>Other photos</b></h3></div>
            {good.photos.others.map((photo, num) => (
                <div key={`dev_photos_${num}`}
                    style={num > 0 ? { paddingTop: '10px', width: 800, clear: "both" } : { width: 800, clear: "both" }}>
                    <Image
                        key={`image_${num}`}
                        style={{ float: "left" }}
                        src={photo}
                        size="medium"
                    />
                    <Button
                        key={`delete_photo_${num}`}
                        style={{ float: "left", marginLeft: 20 }}
                        color="red"
                        onClick={() => { handleDeletePhotoClick(photo) }}>
                        {"Delete"}
                    </Button>
                    <Button
                        key={`set_main_${num}`}
                        style={{ marginLeft: 20 }}
                        color="blue"
                        onClick={() => { handleSetPhotoMainClick(photo) }}>
                        {"Set as Main"}
                    </Button>
                </div>
            ))
            }
            <div style={{ clear: "both", paddingTop: '20px' }}>
                <Button
                    onClick={openFileSelector}
                    disabled={good.id === 0}>
                    {"Upload photo"}
                </Button>
            </div>
            {/* Photos block <<< */}

            <Modal
                centered={true}
                open={openError}
                onClose={() => setOpenError(false)}
                onOpen={() => setOpenError(true)}
            >
                <Modal.Content>
                    <Modal.Description>
                        You must set title in English (US)
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setOpenError(false)}>OK</Button>
                </Modal.Actions>
            </Modal>
        </div >

    )
}
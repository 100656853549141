import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../http";

type Good = {
    id: number;
    title: string;
    sku: string;
}

type Category = {
    title: string;
    name: string;
    description: string;
    goods: Good[];
}

type GoodsResponse = Good[];

type CategoriesResponse = Category[];

type GoodsState = {
    categories: Category[];
    loading: boolean;
    error: string | null;
}

const initialState: GoodsState = {
    categories: [],
    loading: false,
    error: null
}

export const getCategories = createAsyncThunk<CategoriesResponse, void, { rejectValue: string }>(
    'goods/getCategories',
    async (_, { rejectWithValue }) => {
        const response = await api.get(`/shop/goods/categories`);

        if (response.status !== 200) {
            return rejectWithValue('Request ERROR');
        }

        return response.data;
    })

export const getGoods = createAsyncThunk<GoodsResponse, { categoryTitle: string }, { rejectValue: string }>(
    'goods/getGoods',
    async ({ categoryTitle }, { rejectWithValue }) => {
        const response = await api.get(`/shop/goods/all?category=${categoryTitle}`);

        if (response.status !== 200) {
            return rejectWithValue('Request ERROR');
        }

        return response.data;
    })

const goodsSlice = createSlice({
    name: 'goods',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getGoods.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getGoods.fulfilled, (state, action) => {
                state.loading = false;
                // console.log(action.payload);
                state.categories = state.categories.map(category => {
                    if (category.title === action.meta.arg.categoryTitle) {
                        category.goods = action.payload;
                    }
                    return category;
                })
            })
            .addCase(getCategories.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getCategories.fulfilled, (state, action) => {
                state.loading = false;
                // console.log(action.payload);

                state.categories = action.payload;
                const emptyCategory: Category = {
                    title: "<0>",
                    name: "<Without category>",
                    description: "",
                    goods: []
                }

                state.categories = [emptyCategory, ...state.categories]
                // console.log(state.categories);
            })
    }
})

export type { Category, Good };

export const goodsReducer = goodsSlice.reducer;
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Dropdown, Image, Input, Label, Modal, TextArea } from "semantic-ui-react";
import { useFilePicker } from "use-file-picker";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { addUsecaseTag, deleteUsecaseImage, deleteUsecaseItem, getUsecaseItem, removeUsecaseTag, saveUsecaseItem, setUsecaseDescription, setUsecaseText, setUsecaseTitle, uploadUsecaseImage } from "../../store/slices/UsecaseItemSlice";
import { getTags } from "../../store/slices/UsecasesSlice";
import { LanguageType } from "../../types/language.type";

export const UsecaseItemPage: React.FC = () => {

    const quillModules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image'],
            ['clean']
        ],
    };

    const langOptions = [
        { key: "en", text: "English (US)", value: "en" },
        { key: "es", text: "Spanish", value: "es" },
        { key: "ru", text: "Russian", value: "ru" },
    ]

    const params = useParams();

    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const usecase = useAppSelector(state => state.usecaseItem.usecaseItem);
    const usecaseState = useAppSelector(state => state.usecaseItem);
    const tagsList = useAppSelector(state => state.usecases.tags);

    const [curLang, setCurLang] = useState("en");

    const [addTagValue, setAddTagValue] = useState("");

    const [openError, setOpenError] = useState(false);

    useEffect(() => {
        const idUsecase = params.idUsecase as unknown as number;
        dispatch(getUsecaseItem(idUsecase));
        dispatch(getTags());
    }, [dispatch, params]);

    const handleBackClick = () => {
        navigate("/usecases");
    }

    const handleDropdownLangChange = (event: any, { value }: any) => {
        // console.log(value);
        setCurLang(value)
    }

    const handleSaveClick = () => {
        if (!usecase.title.en?.trim()) {
            setOpenError(true);
        } else {
            dispatch(saveUsecaseItem(usecase));
            if (usecase.id === 0) {
                navigate("/usecases");
            }
        }
    }

    const handleClearChangesClick = () => {
        if (usecase?.id && usecase?.id > 0) {
            dispatch(getUsecaseItem(usecase?.id))
        }
    }

    const handleDeleteClick = () => {
        if (usecase?.id && usecase?.id > 0) {
            dispatch(deleteUsecaseItem(usecase?.id));
            navigate("/usecases");
        }
    }

    const handleAddTagClick = (tagTitle: string) => {
        dispatch(addUsecaseTag(tagTitle))
    }

    const handleRemoveTagClick = (tagTitle: string) => {
        dispatch(removeUsecaseTag(tagTitle))
    }

    const [openFileSelectorMinimalImage, { }] = useFilePicker({
        multiple: false,
        readAs: 'ArrayBuffer',
        accept: ['.jpg', '.jpeg', '.tiff', '.bmp', '.png'],
        maxFileSize: 5, // in megabytes

        onFilesSelected: async ({ plainFiles, filesContent, errors }) => {
            var bodyFormData = new FormData();
            bodyFormData.append('file', plainFiles[0]);
            dispatch(uploadUsecaseImage({ idUsecase: usecase.id, formData: bodyFormData, isMinimal: true }))
        },
    });

    const [openFileSelectorMainImage, { }] = useFilePicker({
        multiple: false,
        readAs: 'ArrayBuffer',
        accept: ['.jpg', '.jpeg', '.tiff', '.bmp', '.png'],
        maxFileSize: 5, // in megabytes

        onFilesSelected: async ({ plainFiles, filesContent, errors }) => {
            var bodyFormData = new FormData();
            bodyFormData.append('file', plainFiles[0]);
            dispatch(uploadUsecaseImage({ idUsecase: usecase.id, formData: bodyFormData, isMinimal: false }))
        },
    });

    const handleDeleteImageClick = (minimal: boolean) => {
        dispatch(deleteUsecaseImage({ idUsecase: usecase.id, isMinimal: minimal }))
    }

    return (
        <div>
            <div style={{ marginBottom: 30 }}>
                <Button
                    onClick={handleBackClick}>
                    {"Back"}
                </Button>
            </div>
            <Dropdown style={{ marginBottom: '20px' }}
                placeholder='Select language' selection
                options={langOptions}
                defaultValue={curLang}
                onChange={handleDropdownLangChange}
            />
            <Button
                style={{ marginLeft: 30 }}
                loading={usecaseState.loading}
                onClick={handleSaveClick}>
                {"Save"}
            </Button>

            <Button
                style={{ marginLeft: 30 }}
                onClick={handleClearChangesClick}>
                {"Clear changes"}
            </Button>

            {usecase.id > 0 &&
                <Button
                    style={{ marginLeft: 30 }}
                    color="red"
                    onClick={handleDeleteClick}>
                    {"Delete"}
                </Button>
            }

            {/* Tags block >>> */}
            <div style={{ marginTop: '30px' }}><h2><b>Tags</b></h2></div>
            <Label.Group color='blue'>
                {usecase.tags &&
                    usecase.tags.map((tag) => (
                        <Label key={tag}
                            as='a'
                            onClick={() => handleRemoveTagClick(tag)}>
                            {tag}
                        </Label>
                    ))
                }
            </Label.Group>
            <Label.Group color='grey'>
                {tagsList &&
                    tagsList.map((tagItem) => (
                        tagItem.title !== "<Without tag>" && !usecase.tags.includes(tagItem.title) ?
                            <Label key={tagItem.title}
                                as='a'
                                onClick={() => handleAddTagClick(tagItem.title)}>
                                {tagItem.title}
                            </Label>
                            : ""
                    ))
                }
            </Label.Group>
            <Input
                placeholder='New tag'
                value={addTagValue}
                onChange={(e) => { setAddTagValue(e.target.value) }}
                onKeyPress={(e: any) => {
                    if (e.key === 'Enter') {
                        handleAddTagClick(addTagValue);
                    }
                }}
            />
            {/* Tags block <<< */}

            {/* Title block >>> */}
            <div style={{ marginTop: '30px' }}><h2><b>Title</b></h2></div>
            <Input
                style={{ width: '500px' }}
                placeholder='Title'
                value={usecase.title && usecase.title[curLang as keyof LanguageType] ? usecase.title[curLang as keyof LanguageType] : ""}
                onChange={(e) => dispatch(setUsecaseTitle({ lang: curLang, value: e.target.value }))}
            />
            {/* Title block <<< */}

            {/* Image minimal block <<< */}
            <div style={{ marginTop: '30px' }}>
                <h2><b>Image minimal</b></h2>
            </div>
            <Button
                style={{ marginTop: '20px' }}
                loading={usecaseState.loading}
                disabled={usecase.id === 0}
                onClick={openFileSelectorMinimalImage}>
                {"Upload"}
            </Button>
            <Button
                style={{ marginTop: '20px' }}
                loading={usecaseState.loading}
                disabled={usecase.img_min ? false : true}
                onClick={() => handleDeleteImageClick(true)}>
                {"Delete"}
            </Button>
            {usecase.img &&
                <Image
                    src={usecase.img_min}
                    size="small" />
            }
            {/* Image minimal block <<< */}

            {/* Image main block <<< */}
            <div style={{ marginTop: '30px' }}>
                <h2><b>Image main</b></h2>
            </div>
            <Button
                style={{ marginTop: '20px' }}
                loading={usecaseState.loading}
                disabled={usecase.id === 0}
                onClick={openFileSelectorMainImage}>
                {"Upload"}
            </Button>
            <Button
                style={{ marginTop: '20px' }}
                loading={usecaseState.loading}
                disabled={usecase.img ? false : true}
                onClick={() => handleDeleteImageClick(false)}>
                {"Delete"}
            </Button>
            {usecase.img &&
                <Image
                    src={usecase.img}
                    size="medium" />
            }
            {/* Image main block <<< */}

            {/* Description block >>> */}
            <div style={{ marginTop: '30px' }}><h2><b>Description</b></h2></div>
            <TextArea
                style={{ width: '600px' }}
                rows={4}
                placeholder='Description'
                value={usecase.description && usecase.description[curLang as keyof LanguageType] ? usecase.description[curLang as keyof LanguageType] : ""}
                onChange={(e) => dispatch(setUsecaseDescription({ lang: curLang, value: e.target.value }))}
            />
            {/* Description block <<< */}

            {/* Text block >>> */}
            <div style={{ marginTop: '30px' }}><h2><b>Article</b></h2></div>
            <div style={{ width: "800px", height: "600px", overflowY: 'auto' }}>
                <ReactQuill
                    style={{ height: "550px" }}
                    theme="snow"
                    value={usecase.text && usecase.text[curLang as keyof LanguageType] ? usecase.text[curLang as keyof LanguageType] : ""}
                    modules={quillModules}
                    onChange={(value) => {
                        dispatch(setUsecaseText({ lang: curLang, value }))
                    }} />
            </div>
            {/* Text block <<< */}

            <Modal
                centered={true}
                open={openError}
                onClose={() => setOpenError(false)}
                onOpen={() => setOpenError(true)}
            >
                <Modal.Content>
                    <Modal.Description>
                        You must set title in English (US)
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setOpenError(false)}>OK</Button>
                </Modal.Actions>
            </Modal>
        </div >
    )

}
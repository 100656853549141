import { Link } from "react-router-dom";
import { useAppSelector } from "../hooks";

interface NaviMenuItemParams {
    id: string;
    title: string;
    link_to: string;
}

export const NaviMenuItem: React.FC<NaviMenuItemParams> = ({ id, title, link_to }) => {
    const activeItem = useAppSelector(state => state.activePage.value);

    return (
        <Link
            className={`navi-item ${activeItem === id ? "navi-item-active" : ""}`}
            to={link_to}
        >
            {title}
        </Link >
    )
}
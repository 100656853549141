import { Image } from "semantic-ui-react";
import { NaviMenuItem } from "./NaviMenuItem";

export const NaviMenu: React.FC = () => {

    // const hide: boolean = true;

    return (
        // hide ?
        // <div className="navi-main color-bg-main">
        //     <Button>Show</Button>
        // </div>
        // :
        <div className="navi-main color-bg-main">
            {/* <Button>Hide</Button> */}
            <Image className="navi-logo" src="/img/sunny-logo-band-alt.png" />
            <nav className="navi-menu">
                {/* <NaviMenuItem id="dashboard" title="Dashboard" link_to="dashboard" /> */}
                <NaviMenuItem id="devices" title="Devices" link_to="devices" />
                <NaviMenuItem id="goods" title="Goods" link_to="goods" />
                <NaviMenuItem id="usecases" title="Usecases" link_to="usecases" />
                {/* <NaviMenuItem id="competencies" title="Competencies" link_to="competencies" /> */}
                {/* <NaviMenuItem id="customers" title="Customers" link_to="customers" /> */}
            </nav>
        </div>


    );
}
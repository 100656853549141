import { Buffer } from "buffer";
import { Navigate, Route, Routes } from "react-router-dom";
import { NaviMenu } from "./components/NaviMenu";
import { useAppSelector } from "./hooks";
import { CustomersPage } from "./pages/CustomersPage";
import { DashboardPage } from "./pages/DashboardPage";
import { DeviceDetailsPage } from "./pages/DeviceDetailsPage";
import { DeviceRemotePage } from "./pages/DeviceRemotePage";
import { LoginPage } from "./pages/LoginPage";
import { ActivePageChanger } from "./pages/PageTools";
import { CompetenciesPage } from "./pages/competencies/CompetenciesPage";
import { DevicesPage } from "./pages/devices/DevicesPage";
import { GoodItemPage } from "./pages/goods/GoodItemPage";
import { GoodsPage } from "./pages/goods/GoodsPage";
import { UsecaseItemPage } from "./pages/usecases/UsecaseItemPage";
import { UsecasesPage } from "./pages/usecases/UsecasesPage";



function App() {
  const refreshToken = useAppSelector(state => state.user.refreshToken);
  // console.log("refreshToken: " + refreshToken);

  ActivePageChanger();

  var tokenExpired = false;
  if (refreshToken) {
    const dateExpired = new Date(JSON.parse(Buffer.from(refreshToken.split(".")[1], 'base64').toString()).exp * 1000);
    tokenExpired = dateExpired < new Date() ? true : false;
  }
  // console.log("tokenExpired: " + tokenExpired);
  if (tokenExpired || !refreshToken || refreshToken.length === 0) {
    // console.log("assss");
    // localStorage.setItem('accessToken', "");
    // localStorage.setItem('refreshToken', "");
    return (
      <LoginPage />
    )
  }

  return (
    <>
      <NaviMenu />
      <div className="main">
        <Routes>
          <Route path="/" element={<Navigate to="/devices" />}></Route>
          <Route path="dashboard" element={<DashboardPage />}></Route>
          <Route path="devices" element={<DevicesPage />} />
          <Route path="devices/details/:idDevice" element={<DeviceDetailsPage />} />
          <Route path="devices/remote/:idDevice" element={<DeviceRemotePage />} />
          <Route path="goods" element={<GoodsPage />} />
          <Route path="goods/:idGood" element={<GoodItemPage />} />
          <Route path="usecases" element={<UsecasesPage />} />
          <Route path="usecases/:idUsecase" element={<UsecaseItemPage />} />
          <Route path="competencies" element={<CompetenciesPage />} />
          <Route path="customers" element={<CustomersPage />}></Route>
        </Routes>
      </div>
    </>
  );
}

export default App;
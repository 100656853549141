import { configureStore } from "@reduxjs/toolkit";
import { activePageReducer } from "./slices/ActivePageSlice";
import { deviceDetailsReducer } from "./slices/DeviceDetailsSlice";
import { deviceSensorsReducer } from './slices/DeviceSensorsSlice';
import { devicesReducer } from './slices/DevicesSlice';
import { goodItemReducer } from "./slices/GoodItemSlice";
import { goodsReducer } from "./slices/GoodsSlice";
import { usecaseItemReducer } from "./slices/UsecaseItemSlice";
import { usecasesReducer } from "./slices/UsecasesSlice";
import { userReducer } from './slices/UserSlice';


export const store = configureStore({
    reducer: {
        activePage: activePageReducer,
        devices: devicesReducer,
        deviceDetails: deviceDetailsReducer,
        user: userReducer,
        deviceSensors: deviceSensorsReducer,
        goods: goodsReducer,
        goodItem: goodItemReducer,
        usecases: usecasesReducer,
        usecaseItem: usecaseItemReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
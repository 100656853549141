import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, List } from "semantic-ui-react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getCategories, getGoods } from "../../store/slices/GoodsSlice";

const GoodsList = () => {

    const categoriesList = useAppSelector(state => state.goods.categories);

    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getCategories())
    }, [dispatch])

    const handleAddGoodClick = () => {
        navigate("0")
    }

    return (
        <div>
            <Button
                style={{ marginLeft: 30 }}
                onClick={handleAddGoodClick}>
                {"Add good"}
            </Button>
            <List>
                {categoriesList.map((category) => (
                    <List.Item key={category.title}>
                        <List.Icon name="folder" />
                        <List.Content>
                            <List.Header style={{ cursor: 'pointer' }}
                                onClick={() => dispatch(getGoods({ categoryTitle: category.title }))}>{category.name}
                            </List.Header>
                            {
                                category.goods ?
                                    category.goods.map((good) => (
                                        <List.List key={good.id}>
                                            <List.Item>
                                                <List.Icon name="file" />
                                                <List.Content>
                                                    <List.Header style={{ cursor: 'pointer' }}>
                                                        <Link to={`${good.id}`}>{good.title}</Link>
                                                    </List.Header>
                                                </List.Content>
                                            </List.Item>
                                        </List.List>
                                    )) : ""
                            }
                        </List.Content>
                    </List.Item>
                ))}
            </List>
        </div>
    );
}

export default GoodsList;
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import uuid from "react-uuid";
import AuthService from "../../services/AuthService";
import { IUser } from "../../services/IUser";
import UserService from "../../services/UserService";

type LoginPassword = {
    login: string,
    password: string
}

type UserState = {
    accessToken: string | null,
    refreshToken: string | null,
    user: IUser | null,
}

const initialState: UserState = {
    accessToken: localStorage.getItem('accessToken'),
    refreshToken: localStorage.getItem('refreshToken'),
    user: null,
}

export const doLogin = createAsyncThunk<UserState, LoginPassword, { rejectValue: string }>(
    'user/doLogin',
    async (loginPassword, { rejectWithValue }) => {
        var fingerprint = localStorage.getItem('fingerprint');
        if (!fingerprint) {
            fingerprint = uuid();
            localStorage.setItem("fingerprint", fingerprint)
        }
        const response = await AuthService.login(loginPassword.login, loginPassword.password, fingerprint);

        if (response.status !== 200) {
            return rejectWithValue('Request ERROR');
        }

        return response.data;
    })
export const doRefreshTokens = createAsyncThunk<{ accessToken: string, refreshToken: string }, undefined, { rejectValue: string }>(
    'user/doRefreshTokens',
    async (_, { rejectWithValue }) => {
        const response = await AuthService.refresh();

        if (response.status !== 200) {
            return rejectWithValue('Request ERROR');
        }

        return response.data;
    })
export const getUser = createAsyncThunk<IUser, undefined, { rejectValue: string }>(
    'user/getUser',
    async (_, { rejectWithValue }) => {
        const response = await UserService.getMe();

        if (response.status !== 200) {
            return rejectWithValue('Request ERROR');
        }

        return response.data;
    })

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserState(state, action: PayloadAction<UserState>) {
            state.accessToken = action.payload.accessToken;
            state.refreshToken = action.payload.refreshToken;
            const accessToken = action.payload.accessToken ? action.payload.accessToken : "";
            const refreshToken = action.payload.refreshToken ? action.payload.refreshToken : "";
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('refreshToken', refreshToken);
            state.user = action.payload.user;
        },
        setTokens(state, action: PayloadAction<{ accessToken: string, refreshToken: string }>) {
            state.accessToken = action.payload.accessToken;
            state.refreshToken = action.payload.refreshToken;
            const accessToken = action.payload.accessToken ? action.payload.accessToken : "";
            const refreshToken = action.payload.refreshToken ? action.payload.refreshToken : "";
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('refreshToken', refreshToken);
        },
        clearUserState(state) {
            state.accessToken = null;
            state.refreshToken = null;
            state.user = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(doLogin.fulfilled, (state, action) => {
                userSlice.caseReducers.setUserState(state, action);
            })
            .addCase(doRefreshTokens.fulfilled, (state, action) => {
                userSlice.caseReducers.setTokens(state, action);
            })
            .addCase(getUser.fulfilled, (state, action) => {
                state.user = action.payload;
            })
    }
})

export const { setUserState, clearUserState } = userSlice.actions;
export type { LoginPassword };

export const userReducer = userSlice.reducer;
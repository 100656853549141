export const DeviceSensors = {

    AXIS_DISPLAY: "display",
    AXIS_VERTICAL: "vertical",
    AXIS_HORIZONTAL: "horizontal",
    LASER_ON: "laser_on",
    FLASHLIGHT_ON: "flashlight_on",
    VOLUME_MUSIC: "volume_music",
    BRIGHTNESS: "brightness",

}
import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { DeviceSensors } from "../../tools/const";

type SensorState = {
    value: undefined | boolean | string | number,
    loading: boolean,
    flagChange: number,
}

type SensorsSettings = {
    // axis_x_max: number,
    // axis_y_max: number,
    // axis_z_max: number,
    volume_music_max: number,
    brightness_max: number,
}

type SensorsLocal = {
    display_local: number,
    vertical_local: number,
    horizontal_local: number,
    volume_music_local: number,
    brightness_local: number,
}

type DeviceSensorsState = {
    laser_on?: SensorState,
    flashlight_on?: SensorState,
    display?: SensorState,
    vertical?: SensorState,
    horizontal?: SensorState,
    volume_music: SensorState,
    brightness: SensorState,
}

type SensorsState = {
    ident: string,
    sensorsSettings: SensorsSettings,
    sensorsLocal: SensorsLocal,
    sensors: DeviceSensorsState
}

type SetDeviceSensor = {
    ident: string,
    sensorName: string,
    sensorValue: string
}

type SetSensors = {
    laser_on?: boolean,
    display?: number,
    vertical?: number,
    horizontal?: number,
    flashlight_on?: boolean,
    volume_music: number,
    brightness: number,
    volume_music_max: number,
    brightness_max: number,
}

const initDeviceSensors: DeviceSensorsState = {
    laser_on: { value: false, loading: true, flagChange: 0 },
    flashlight_on: { value: false, loading: true, flagChange: 0 },
    display: { value: 0, loading: true, flagChange: 0 },
    vertical: { value: 0, loading: true, flagChange: 0 },
    horizontal: { value: 0, loading: true, flagChange: 0 },
    volume_music: { value: 0, loading: true, flagChange: 0 },
    brightness: { value: 0, loading: true, flagChange: 0 },
}

const initSensorsSettings: SensorsSettings = {
    volume_music_max: 0,
    brightness_max: 0
}

const initSensorsLocal: SensorsLocal = {
    display_local: 0,
    vertical_local: 0,
    horizontal_local: 0,
    volume_music_local: 0,
    brightness_local: 0,
}

const initialState: SensorsState = {
    ident: "",
    sensorsSettings: initSensorsSettings,
    sensorsLocal: initSensorsLocal,
    sensors: initDeviceSensors
}

export function sensorLoading(sensorName: string) {
    return (dispatch: Dispatch, getState: any) => {
        dispatch(setSensorLoading(sensorName));

        // stop loading while no answer
        var flagChange = 0;

        switch (sensorName) {
            case DeviceSensors.LASER_ON:
                flagChange = getState().deviceSensors.sensors.laser_on.flagChange;
                break;
            case DeviceSensors.FLASHLIGHT_ON:
                flagChange = getState().deviceSensors.sensors.flashlight_on.flagChange;
                break;
            case DeviceSensors.AXIS_DISPLAY:
                flagChange = getState().deviceSensors.sensors.display.flagChange;
                break;
            case DeviceSensors.AXIS_VERTICAL:
                flagChange = getState().deviceSensors.sensors.vertical.flagChange;
                break;
            case DeviceSensors.AXIS_HORIZONTAL:
                flagChange = getState().deviceSensors.sensors.horizontal.flagChange;
                break;
            case DeviceSensors.VOLUME_MUSIC:
                flagChange = getState().deviceSensors.sensors.volume_music.flagChange;
                break;
            case DeviceSensors.BRIGHTNESS:
                flagChange = getState().deviceSensors.sensors.brightness.flagChange;
                break;
        }
        setTimeout(() => dispatch(setSensorStopLoading({ sensorName, flagChange })), 10000);
    }
}

const deviceSensorsSlice = createSlice({
    name: 'deviceSensors',
    initialState,
    reducers: {
        // setDeviceSensor(state, action: PayloadAction<SetDeviceSensor>) {
        //     if (action.payload.ident !== state.ident) {
        //         switch (action.payload.sensorName) {
        //             case "stateLaser":
        //                 console.log("stateLaser");
        //                 state.sensors.laser = { value: (action.payload.sensorValue as unknown as boolean), loading: false };
        //                 break;
        //             default:
        //                 break;
        //         }
        //     }
        // },
        setIdent(state, action: PayloadAction<string>) {
            if (action.payload !== state.ident) {
                //console.log("NEW IDENT: '" + action.payload + "'");

                state.ident = action.payload;
                state.sensors = initDeviceSensors;
                state.sensorsLocal = initSensorsLocal;
            }
        },
        setLocalAxisDisplay(state, action: PayloadAction<number>) {
            // console.log("setLocalAxisX: " + action.payload);
            state.sensorsLocal.display_local = action.payload;
        },
        setLocalAxisVertical(state, action: PayloadAction<number>) {
            state.sensorsLocal.vertical_local = action.payload;
        },
        setLocalAxisHorizontal(state, action: PayloadAction<number>) {
            state.sensorsLocal.horizontal_local = action.payload;
        },
        setLocalVolumeMusic(state, action: PayloadAction<number>) {
            state.sensorsLocal.volume_music_local = action.payload;
        },
        setLocalBrightness(state, action: PayloadAction<number>) {
            state.sensorsLocal.brightness_local = action.payload;
        },
        setSensorsState(state, action: PayloadAction<SetSensors>) {
            // console.log("setSensorsState: " + action.payload.laser);
            const sensors = state.sensors;
            if ("volume_music_max" in action.payload) {
                state.sensorsSettings.volume_music_max = action.payload.volume_music_max as number;
            }
            if ("brightness_max" in action.payload) {
                state.sensorsSettings.brightness_max = action.payload.brightness_max as number;
            }
            if (DeviceSensors.AXIS_DISPLAY in action.payload && sensors.display) {
                // console.log("set rotateX: " + action.payload.rotateX);
                sensors.display.value = action.payload.display;
                sensors.display.loading = false;
                state.sensorsLocal.display_local = sensors.display.value as number;
            }
            if (DeviceSensors.AXIS_VERTICAL in action.payload && sensors.vertical) {
                // console.log("set rotateY: " + action.payload.rotateY);
                sensors.vertical.value = action.payload.vertical;
                sensors.vertical.loading = false;
                state.sensorsLocal.vertical_local = sensors.vertical.value as number;
            }
            if (DeviceSensors.AXIS_HORIZONTAL in action.payload && sensors.horizontal) {
                // console.log("set rotateY: " + action.payload.rotateY);
                sensors.horizontal.value = action.payload.horizontal;
                sensors.horizontal.loading = false;
                state.sensorsLocal.horizontal_local = sensors.horizontal.value as number;
            }
            if (DeviceSensors.VOLUME_MUSIC in action.payload && sensors.volume_music) {
                sensors.volume_music.value = action.payload.volume_music;
                sensors.volume_music.loading = false;
                state.sensorsLocal.volume_music_local = sensors.volume_music.value as number;
            }
            if (DeviceSensors.BRIGHTNESS in action.payload && sensors.brightness) {
                sensors.brightness.value = action.payload.brightness;
                sensors.brightness.loading = false;
                state.sensorsLocal.brightness_local = sensors.brightness.value as number;
            }
            if (DeviceSensors.LASER_ON in action.payload && sensors.laser_on) {
                const value = typeof action.payload.laser_on === "boolean"
                    ? action.payload.laser_on : (action.payload.laser_on === "1" ? true : false);
                sensors.laser_on.value = value;
                sensors.laser_on.loading = false;
            }
            if (DeviceSensors.FLASHLIGHT_ON in action.payload && sensors.flashlight_on) {
                const value = typeof action.payload.flashlight_on === "boolean"
                    ? action.payload.flashlight_on : (action.payload.flashlight_on === "1" ? true : false);
                sensors.flashlight_on.value = value;
                sensors.flashlight_on.loading = false;
            }
            state.sensors = sensors;
        },
        setSensorLoading(state, action: PayloadAction<string>) {
            var sensors = state.sensors;
            switch (action.payload) {
                case DeviceSensors.AXIS_DISPLAY:
                    if (sensors.display) {
                        sensors.display.loading = true;
                        sensors.display.flagChange = sensors.display.flagChange + 1;
                        // console.log("flagChange setSensorLoading: " + sensors.laser?.flagChange);
                    }
                    break;
                case DeviceSensors.AXIS_VERTICAL:
                    if (sensors.vertical) {
                        sensors.vertical.loading = true;
                        sensors.vertical.flagChange = sensors.vertical.flagChange + 1;
                    }
                    break;
                case DeviceSensors.AXIS_HORIZONTAL:
                    if (sensors.horizontal) {
                        sensors.horizontal.loading = true;
                        sensors.horizontal.flagChange = sensors.horizontal.flagChange + 1;
                    }
                    break;
                case DeviceSensors.VOLUME_MUSIC:
                    if (sensors.volume_music) {
                        sensors.volume_music.loading = true;
                        sensors.volume_music.flagChange = sensors.volume_music.flagChange + 1;
                    }
                    break;
                case DeviceSensors.BRIGHTNESS:
                    if (sensors.brightness) {
                        sensors.brightness.loading = true;
                        sensors.brightness.flagChange = sensors.brightness.flagChange + 1;
                    }
                    break;
                case DeviceSensors.LASER_ON:
                    if (sensors.laser_on) {
                        sensors.laser_on.loading = true;
                        sensors.laser_on.flagChange = sensors.laser_on.flagChange + 1;
                        // console.log("flagChange setSensorLoading: " + sensors.laser?.flagChange);
                    }
                    break;
                case DeviceSensors.FLASHLIGHT_ON:
                    if (sensors.flashlight_on) {
                        sensors.flashlight_on.loading = true;
                        sensors.flashlight_on.flagChange = sensors.flashlight_on.flagChange + 1;
                        // console.log("flagChange setSensorLoading: " + sensors.flashlight_on?.flagChange);
                    }
                    break;
            }
            state.sensors = sensors;
        },
        setSensorStopLoading(state, action: PayloadAction<{ sensorName: string, flagChange: number }>) {
            // console.log("setSensorStopLoading");

            var sensors = state.sensors;
            switch (action.payload.sensorName) {
                case DeviceSensors.AXIS_DISPLAY:
                    if (sensors.display) {
                        // console.log("setSensorStopLoading flagChangeLocal: " + action.payload.flagChange);
                        // console.log("setSensorStopLoading flagChange: " + sensors.axis_x.flagChange);
                        if (sensors.display.flagChange === action.payload.flagChange) {
                            sensors.display.loading = false;
                            // sensors.axis_x.value = state.sensorsLocal.axis_x_local;
                            state.sensorsLocal.display_local = (Number)(sensors.display.value);
                        }
                    }
                    break;
                case DeviceSensors.AXIS_VERTICAL:
                    if (sensors.vertical) {
                        // console.log("setSensorStopLoading flagChangeLocal: " + action.payload.flagChange);
                        // console.log("setSensorStopLoading flagChange: " + sensors.axis_y.flagChange);
                        if (sensors.vertical.flagChange === action.payload.flagChange) {
                            sensors.vertical.loading = false;
                            // sensors.axis_y.value = state.sensorsLocal.axis_y_local;
                            state.sensorsLocal.vertical_local = (Number)(sensors.vertical.value);
                        }
                    }
                    break;
                case DeviceSensors.AXIS_HORIZONTAL:
                    if (sensors.horizontal) {
                        // console.log("setSensorStopLoading flagChangeLocal: " + action.payload.flagChange);
                        // console.log("setSensorStopLoading flagChange: " + sensors.axis_y.flagChange);
                        if (sensors.horizontal.flagChange === action.payload.flagChange) {
                            sensors.horizontal.loading = false;
                            state.sensorsLocal.horizontal_local = (Number)(sensors.horizontal.value);
                        }
                    }
                    break;
                case DeviceSensors.VOLUME_MUSIC:
                    if (sensors.volume_music) {
                        if (sensors.volume_music.flagChange === action.payload.flagChange) {
                            sensors.volume_music.loading = false;
                            state.sensorsLocal.volume_music_local = (Number)(sensors.volume_music.value);
                        }
                    }
                    break;
                case DeviceSensors.BRIGHTNESS:
                    if (sensors.brightness) {
                        if (sensors.brightness.flagChange === action.payload.flagChange) {
                            sensors.brightness.loading = false;
                            state.sensorsLocal.brightness_local = (Number)(sensors.brightness.value);
                        }
                    }
                    break;
                case DeviceSensors.LASER_ON:
                    if (sensors.laser_on) {
                        // console.log("setSensorStopLoading flagChangeLocal: " + action.payload.flagChange);
                        // console.log("setSensorStopLoading flagChange: " + sensors.laser.flagChange);
                        if (sensors.laser_on.flagChange === action.payload.flagChange) {
                            sensors.laser_on.loading = false;
                        }
                    }
                    break;
                case DeviceSensors.FLASHLIGHT_ON:
                    if (sensors.flashlight_on) {
                        // console.log("setSensorStopLoading flagChangeLocal: " + action.payload.flagChange);
                        // console.log("setSensorStopLoading flagChange: " + sensors.flashlight_on.flagChange);
                        if (sensors.flashlight_on.flagChange === action.payload.flagChange) {
                            sensors.flashlight_on.loading = false;
                        }
                    }
                    break;
            }
            state.sensors = sensors;
        },
    }
})

export type { DeviceSensorsState, SetDeviceSensor };

export const {
    setIdent,
    setLocalAxisDisplay,
    setLocalAxisVertical,
    setLocalAxisHorizontal,
    setLocalVolumeMusic,
    setLocalBrightness,
    setSensorsState,
    setSensorLoading,
    setSensorStopLoading
} = deviceSensorsSlice.actions;

export const deviceSensorsReducer = deviceSensorsSlice.reducer;

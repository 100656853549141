import { useEffect } from 'react';
import { Container, Input } from 'semantic-ui-react';
import { useAppDispatch } from '../../hooks';
import { clearDeviceDetailsState } from '../../store/slices/DeviceDetailsSlice';
import { setIdent } from '../../store/slices/DeviceSensorsSlice';

import DevicesTable from "./DevicesTable";

export const DevicesPage = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setIdent(""));
        dispatch(clearDeviceDetailsState());
    })

    return (
        <Container className="page-devices">
            <Input />
            <DevicesTable />
        </Container>
    )
}
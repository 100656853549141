import { Link } from "react-router-dom";
import { Table } from "semantic-ui-react";
import { Device } from "../../store/slices/DevicesSlice";


const DeviceRow = (device: Device) => {

    return (
        <Table.Row>
            <Table.Cell>{device.ident}</Table.Cell>
            <Table.Cell style={device.status == "online" ? { color: 'green' } : { color: 'red' }}>{device.status}</Table.Cell>
            <Table.Cell>{device.hardware_version}</Table.Cell>
            <Table.Cell>
                <Link to={`details/${device.id}`}>Details</Link>
                <Link style={{ marginLeft: '10px' }} to={`remote/${device.id}`}>Jump</Link>
            </Table.Cell>
        </Table.Row >
    )
}

export default DeviceRow;
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../http/index';
import { LanguageType } from '../../types/language.type';
import { PhotosType } from '../../types/photos.type';
import { PriceType } from '../../types/price.type';

type SpecType = {
    title: string,
    value: string
}

export type LangSpecType = {
    en?: SpecType[],
    es?: SpecType[],
    ru?: SpecType[],
}

type GoodItem = {
    id: number;
    title: LanguageType;
    sku: string;
    price: PriceType;
    description: LanguageType;
    description_short: LanguageType;
    specs: LangSpecType;
    categories: { title: string }[],
    photos: PhotosType,
}

type GoodItemResponse = GoodItem;

type GoodItemState = {
    goodItem: GoodItem;
    loading: boolean;
    error: string | null;
}

const specInit = {
    title: "",
    value: ""
}

const goodItemInit: GoodItem = {
    id: 0,
    title: {},
    sku: '',
    price: {},
    description: {},
    description_short: {},
    specs: {},
    categories: [],
    photos: { feature: "", others: [] }
}

const initialState: GoodItemState = {
    goodItem: goodItemInit,
    loading: false,
    error: null
}

export const getGoodItem = createAsyncThunk<GoodItemResponse, number, { rejectValue: string }>(
    'goods/getGoodItem',
    async (idGood, { rejectWithValue }) => {
        const response = await api.get(`/shop/goods/${idGood}?style=full`);

        if (response.status !== 200) {
            return rejectWithValue('Request ERROR');
        }

        return response.data;
    })

export const saveGoodItem = createAsyncThunk<GoodItemResponse, GoodItem, { rejectValue: string }>(
    'goods/saveGoodItem',
    async (goodItem, { rejectWithValue }) => {
        const { id, ...goodSave } = goodItem;

        const response = goodItem.id === 0
            ? await api.post(`/shop/goods`, goodSave)
            : await api.patch(`/shop/goods/${goodItem.id}`, goodSave);

        if (response.status !== 200) {
            return rejectWithValue('Request ERROR');
        }

        return response.data;
    })

export const deleteGoodItem = createAsyncThunk<GoodItemResponse, number, { rejectValue: string }>(
    'goods/deleteGoodItem',
    async (idGood, { rejectWithValue }) => {
        const response = await api.delete(`/shop/goods/${idGood}`);

        if (response.status !== 200) {
            return rejectWithValue('Request ERROR');
        }

        return response.data;
    })

export const uploadGoodPhoto = createAsyncThunk<GoodItemResponse, { idGood: number, formData: FormData }, { rejectValue: string }>(
    'goods/uploadGoodPhoto',
    async ({ idGood, formData }, { rejectWithValue }) => {
        const response = await api.post(`/shop/goods/photo/${idGood}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });

        if (response.status !== 200) {
            return rejectWithValue('Request ERROR');
        }

        return response.data;
    })

export const deleteGoodPhoto = createAsyncThunk<GoodItemResponse, { idGood: number, filePath: string }, { rejectValue: string }>(
    'goods/deleteGoodPhoto',
    async ({ idGood, filePath }, { rejectWithValue }) => {

        const response = await api.post(`/shop/goods/photo/delete/${idGood}`, { file_path: filePath });

        if (response.status !== 200) {
            return rejectWithValue('Request ERROR');
        }

        return response.data;
    })

export const setGoodPhotoMain = createAsyncThunk<GoodItemResponse, { idGood: number, filePath: string }, { rejectValue: string }>(
    'goods/setGoodPhotoMain',
    async ({ idGood, filePath }, { rejectWithValue }) => {

        const response = await api.post(`/shop/goods/photo/main_photo/${idGood}`, { file_path: filePath });

        if (response.status !== 200) {
            return rejectWithValue('Request ERROR');
        }

        return response.data;
    })

const goodItemSlice = createSlice({
    name: 'goodItem',
    initialState,
    reducers: {
        setGoodSku(state, action: PayloadAction<string>) {
            var goodItemNew = state.goodItem;
            if (goodItemNew) {
                goodItemNew.sku = action.payload;
            }
            state.goodItem = goodItemNew;
        },
        setGoodPrice(state, action: PayloadAction<{ currency: string, value: number }>) {
            var goodItemNew = state.goodItem;
            if (goodItemNew) {
                goodItemNew.price = goodItemNew.price ? goodItemNew.price : {};
                goodItemNew.price[action.payload.currency as keyof PriceType] = action.payload.value + "";
            }
            state.goodItem = goodItemNew;
        },
        setGoodTitle(state, action: PayloadAction<{ lang: string, value: string }>) {
            var goodItemNew = state.goodItem;
            if (goodItemNew) {
                goodItemNew.title = goodItemNew.title ? goodItemNew.title : {};
                goodItemNew.title[action.payload.lang as keyof LanguageType] = action.payload.value;
            }
            state.goodItem = goodItemNew;
        },
        addGoodCategory(state, action: PayloadAction<string>) {
            var goodItemNew = state.goodItem;
            if (goodItemNew) {
                goodItemNew.categories.push({ title: action.payload });
            }
            state.goodItem = goodItemNew;
        },
        removeGoodCategory(state, action: PayloadAction<string>) {
            var goodItemNew = state.goodItem;
            if (goodItemNew) {
                goodItemNew.categories = goodItemNew.categories.filter(category => category.title !== action.payload);
            }
            state.goodItem = goodItemNew;
        },
        setGoodDescriptionShort(state, action: PayloadAction<{ lang: string, value: string }>) {
            var goodItemNew = state.goodItem;
            if (goodItemNew) {
                goodItemNew.description_short = goodItemNew.description_short ? goodItemNew.description_short : {};
                goodItemNew.description_short[action.payload.lang as keyof LanguageType] = action.payload.value;
            }
            state.goodItem = goodItemNew;
        },
        setGoodDescription(state, action: PayloadAction<{ lang: string, value: string }>) {
            var goodItemNew = state.goodItem;
            if (goodItemNew) {
                goodItemNew.description = goodItemNew.description ? goodItemNew.description : {};
                goodItemNew.description[action.payload.lang as keyof LanguageType] = action.payload.value;
            }
            state.goodItem = goodItemNew;
        },
        setGoodSpecTitle(state, action: PayloadAction<{ num: number, lang: string, title: string }>) {
            var stateNew = state;
            if (stateNew.goodItem) {
                const specsForLang = stateNew.goodItem.specs[action.payload.lang as keyof LangSpecType];
                if (specsForLang) {
                    specsForLang[action.payload.num].title = action.payload.title;
                }
            }
            state = stateNew;
        },
        setGoodSpecValue(state, action: PayloadAction<{ num: number, lang: string, value: string }>) {
            var stateNew = state;
            if (stateNew.goodItem) {
                const specsForLang = stateNew.goodItem.specs[action.payload.lang as keyof LangSpecType];
                if (specsForLang) {
                    specsForLang[action.payload.num].value = action.payload.value;
                }
            }
            state = stateNew;
        },
        deleteGoodSpec(state, action: PayloadAction<{ num: number, lang: string }>) {
            var stateNew = state;
            if (stateNew.goodItem) {
                stateNew.goodItem.specs[action.payload.lang as keyof LangSpecType]?.splice(action.payload.num, 1)
                // if (action.payload < stateNew.goodItem.specs.length) {
                //     stateNew.goodItem.specs.splice(action.payload, 1);
                // }
            }
            state = stateNew;
        },
        addGoodSpec(state, action: PayloadAction<string>) {
            var stateNew = state;
            if (stateNew.goodItem) {
                stateNew.goodItem.specs[action.payload as keyof LangSpecType] =
                    stateNew.goodItem.specs[action.payload as keyof LangSpecType]
                        ? stateNew.goodItem.specs[action.payload as keyof LangSpecType]
                        : [];
                const specsForLang = stateNew.goodItem.specs[action.payload as keyof LangSpecType];
                if (specsForLang) {
                    specsForLang.push(specInit)
                }
            }
            state = stateNew;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getGoodItem.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getGoodItem.fulfilled, (state, action) => {
                state.loading = false;
                // console.log(action.payload);
                state.goodItem = action.payload ? action.payload : goodItemInit;
            })
            .addCase(saveGoodItem.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(saveGoodItem.fulfilled, (state, action) => {
                const stateCur = state;
                state.loading = false;

                state.goodItem = action.payload;
                state = stateCur;
            })
            .addCase(deleteGoodItem.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteGoodItem.fulfilled, (state, _action) => {
                const stateCur = state;
                state.loading = false;

                state.goodItem = goodItemInit;
                state = stateCur;
            })
            .addCase(uploadGoodPhoto.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(uploadGoodPhoto.fulfilled, (state, action) => {
                const stateCur = state;
                state.loading = false;

                state.goodItem = action.payload ? action.payload : goodItemInit;
                state = stateCur;
            })
            .addCase(deleteGoodPhoto.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteGoodPhoto.fulfilled, (state, action) => {
                const stateCur = state;
                state.loading = false;

                state.goodItem = action.payload ? action.payload : goodItemInit;
                state = stateCur;
            })
            .addCase(setGoodPhotoMain.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(setGoodPhotoMain.fulfilled, (state, action) => {
                const stateCur = state;
                state.loading = false;

                state.goodItem = action.payload ? action.payload : goodItemInit;
                state = stateCur;
            })
    }
})

export type { GoodItem };

export const {
    setGoodSku, setGoodPrice, setGoodTitle, setGoodDescriptionShort, setGoodDescription,
    addGoodCategory, removeGoodCategory, setGoodSpecTitle, setGoodSpecValue, deleteGoodSpec, addGoodSpec }
    = goodItemSlice.actions;

export const goodItemReducer = goodItemSlice.reducer;
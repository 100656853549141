import { FC, useState } from "react";
import { Form, Image } from "semantic-ui-react";
import { useAppDispatch } from "../hooks";
import { doLogin } from "../store/slices/UserSlice";

export const LoginPage: FC = () => {
    const dispatch = useAppDispatch();

    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");

    return (
        <div
            style={{
                top: 0,
                left: 0,
                backgroundColor: 'FFFAF3',
                position: "absolute",
                width: '100%', height: '100%',
                overflow: 'auto'
            }}
        >

            <div className="login-main-form">
                <span className="color-text-default"
                    style={{ position: 'absolute', display: 'inline-block', top: "29px", left: "39px", 'fontSize': "24px", 'fontWeight': 'lighter' }}
                >Sunny Cloud</span>

                <Form>
                    {/* Login */}
                    <span className="color-labels-borders"
                        style={{ position: 'absolute', display: 'inline-block', top: "67px", left: "56px", 'fontSize': "16px", 'fontWeight': 'lighter' }}
                    >Login</span>
                    <div className='input-login-div' style={{ position: 'absolute', width: '270px', height: '60px', top: '90px', left: '39px' }}>
                        <Form.Input transparent style={{ margin: '0px 15px' }} placeholder="youremail@gmail.com" onChange={(e) => setLogin(e.target.value)} />
                    </div >

                    {/* Password */}
                    <span className="color-labels-borders"
                        style={{ position: 'absolute', display: 'inline-block', top: "172px", left: "56px", 'fontSize': "16px", 'fontWeight': 'lighter' }}
                    >Password</span>
                    <div className='input-login-div' style={{ position: 'absolute', width: '270px', height: '60px', top: '198px', left: '39px' }}>
                        <Form.Input transparent type="password" style={{ margin: '0px 15px' }} placeholder="enter password" onChange={(e) => setPassword(e.target.value)} />
                    </div >

                    <Image src="/img/sunny-logo-big.png" style={{ position: 'absolute', top: '129px', left: '371px' }}></Image>

                    <Form.Button
                        onClick={() => dispatch(doLogin({ login, password }))}
                        style={{ position: 'absolute', display: 'inline-block', top: "297px", left: "56px", 'fontSize': "16px", 'fontWeight': 'lighter' }}

                    >Login</Form.Button>
                </Form>
            </div>

            <div className="centered-x" style={{ position: 'absolute', bottom: '15px' }}>
                <span className="color-main"
                    style={{
                        display: 'inline', 'fontSize': '12px', 'fontWeight': 'lighter', 'textDecoration': 'underline', 'textDecorationThickness': 'from-font'
                    }}
                >Sunny Solutions </span>
                <span className="color-labels-borders" style={{ display: 'inline', 'fontSize': '12px', 'fontWeight': 'lighter' }}> &copy; Sunnyvale CA</span>
            </div>
        </div >
    )
}
import { Container } from "semantic-ui-react"
import CompetenciesTree from "./CompetenciesTree"

export const CompetenciesPage: React.FC = () => {

    return (
        // <div><span>asdasads</span></div>
        <Container className="page-competencies">
            {/* <Input /> */}
            <CompetenciesTree />
        </Container>
    )
}
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../http";

const TITLE_NO_TAG = "<Without tag>";

type Usecase = {
    id: number;
    title: string;
}

type UsecaseTag = {
    title: string;
    usecases: Usecase[];
}

type UsecasesState = {
    tags: UsecaseTag[];
    loading: boolean;
    error: string | null;
}

type UsecasesResponse = Usecase[];

type TagsResponse = UsecaseTag[];

const initialState: UsecasesState = {
    tags: [],
    loading: false,
    error: null
}

export const getTags = createAsyncThunk<TagsResponse, void, { rejectValue: string }>(
    'goods/getUsecaseTags',
    async (_, { rejectWithValue }) => {
        const response = await api.get(`/articles/tags/usecases`);

        if (response.status !== 200) {
            return rejectWithValue('Request ERROR');
        }

        return response.data;
    })

export const getUsecases = createAsyncThunk<UsecasesResponse, { tag: string }, { rejectValue: string }>(
    'goods/getGoods',
    async ({ tag }, { rejectWithValue }) => {
        if (tag === TITLE_NO_TAG) {
            tag = "$no_tags$";
        }
        const response = await api.get(`/articles/usecases/${tag}`);

        if (response.status !== 200) {
            return rejectWithValue('Request ERROR');
        }

        return response.data;
    })

const usecasesSlice = createSlice({
    name: 'usecases',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUsecases.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getUsecases.fulfilled, (state, action) => {
                state.loading = false;
                // console.log(action.payload);
                state.tags = state.tags.map(tag => {
                    if (tag.title === action.meta.arg.tag) {
                        tag.usecases = action.payload;
                    }
                    return tag;
                });
                // console.log(state.tags);

            })
            .addCase(getTags.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getTags.fulfilled, (state, action) => {
                state.loading = false;

                state.tags = action.payload;
                const emptyTag: UsecaseTag = {
                    title: TITLE_NO_TAG,
                    usecases: []
                }

                state.tags = [emptyTag, ...state.tags];
                // console.log(state.tags);
            })
    }
})

export type { Usecase, UsecaseTag };

export const usecasesReducer = usecasesSlice.reducer;
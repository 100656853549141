
import { SingleRangeSlider } from "neo-react-semantic-ui-range";
import 'neo-react-semantic-ui-range/dist/styles.min.css';
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Header, Input } from "semantic-ui-react";
import { Socket, io } from "socket.io-client";
import { useFilePicker } from "use-file-picker";
import { AuthType, createClient } from "webdav";
import { useAppDispatch, useAppSelector } from "../hooks";
import api from "../http";
import { clearDeviceDetailsState, getDeviceDetails } from "../store/slices/DeviceDetailsSlice";
import { sensorLoading, setIdent, setLocalAxisDisplay, setLocalAxisHorizontal, setLocalAxisVertical, setLocalBrightness, setLocalVolumeMusic, setSensorsState } from "../store/slices/DeviceSensorsSlice";
import { doRefreshTokens } from "../store/slices/UserSlice";
import { DeviceSensors } from "../tools/const";

var socket = io();

type MsgToDevice = {
    ident: string | undefined,
    msgType: string,
    payload: string
}

export const DeviceRemotePage = () => {
    // horizontal_full  342  float
    // horizontal_zero  171  float
    // horizontal_ratio  7.7  float
    // vertical_full  130  float
    // vertical_zero  40  float
    // vertical_ratio  19  float
    // display_full  180  float
    // display_zero  135  float
    // display_ratio  7.7  float
    const motor_step_angle = 1.8;

    const axis_display_ratio = 7.7;
    const axis_display_full = 180;
    const axis_display_zero = 135;
    const axis_display_step = motor_step_angle / axis_display_ratio;
    // const axis_display_step = 0.234;
    // console.log(axis_x_step);

    const axis_vertical_ratio = 19;
    const axis_vertical_full = 130;
    const axis_vertical_zero = 40;
    const axis_vertical_step = motor_step_angle / axis_vertical_ratio;
    // const axis_vertical_step = 0.095;
    // console.log(axis_y_step);


    const axis_horizontal_ratio = 7.7;
    const axis_horizontal_full = 342;
    const axis_horizontal_zero = 171;
    const axis_horizontal_step = motor_step_angle / axis_horizontal_ratio;
    // const axis_horizontal_step = 0.234;

    const params = useParams();

    const dispatch = useAppDispatch();

    useEffect(() => {
        // console.log("useEffect " + params.idDevice);
        dispatch(setIdent(""));
        dispatch(clearDeviceDetailsState());
        dispatch(getDeviceDetails(params.idDevice as unknown as number));
    }, [dispatch, params]);

    const device = useAppSelector(state => state.deviceDetails.device);

    const deviceSensors = useAppSelector(state => state.deviceSensors);

    const [textToSay, setTextToSay] = useState("");

    const [ledNumber, setLedNumber] = useState("");

    const [ledColor, setLedColor] = useState("");

    const [showVideo, setShowVideo] = useState(false);

    const [statusSendFile, setStatusSendFile] = useState("");

    const [openFileSelector, { filesContent }] = useFilePicker({
        // const [openFileSelector, { filesContent, loading, errors, plainFiles, clear }] = useFilePicker({
        multiple: false,
        readAs: 'ArrayBuffer', // availible formats: "Text" | "BinaryString" | "ArrayBuffer" | "DataURL"
        // accept: '.ics,.pdf',
        accept: ['.jpg', '.jpeg', '.tiff', '.bmp', '.png'],
        // limitFilesConfig: { min: 2, max: 3 },
        // minFileSize: 1, // in megabytes
        maxFileSize: 1,
        // readFilesContent: false, // ignores file content
    });

    const sendFile = async () => {
        setStatusSendFile("Получение данных аккаунта...");

        let ocLogin = localStorage.getItem('oc_login') || "";
        let ocPassword = localStorage.getItem('oc_pswd') || "";
        let ocTimeCreate = Number(localStorage.getItem('oc_timecreate'));
        // console.log(ocTimeCreate);
        // console.log(Math.round(new Date().getTime() / 1000));

        if (!ocTimeCreate || ocTimeCreate + 120 < Math.round(new Date().getTime() / 1000)) {
            const response = await api.get(`/owncloud/password_token`);
            if (response.status !== 200) {
                setStatusSendFile("Данные аккаунта не получены");;
            }

            localStorage.setItem('oc_login', response.data.loginName);
            localStorage.setItem('oc_pswd', response.data.token);
            localStorage.setItem('oc_timecreate', Math.round(new Date().getTime() / 1000) + "");

            ocLogin = response.data.loginName;
            ocPassword = response.data.token;
        }
        // console.log(ocLogin + " " + ocPassword);

        setStatusSendFile("Отправка файла...");

        const client = createClient(`https://files.sunny.solutions/remote.php/dav/files/${ocLogin}`, {
            authType: AuthType.Password,
            username: ocLogin,
            password: ocPassword,
        });
        // console.log(client);

        // console.log(filesContent[0].name);
        // console.log(filesContent[0].content);
        const fileRemotePath = "TempShare/" + filesContent[0].name;
        const resUpload = await client.putFileContents(fileRemotePath, filesContent[0].content, { overwrite: true });
        // console.log(resUpload);

        // const resp = await client.getDirectoryContents("/Photos/");
        // // const result: DAVResult = await parseXML(resp.data);
        // console.log(resp);

        if (resUpload) {
            setStatusSendFile("Предоставление доступа sunny к файлу...");
            const res = await api.post(`/owncloud/share_file`, {
                username: ocLogin,
                filePath: fileRemotePath
            });
            if (res.status <= 201) {
                const link = `https://files.sunny.solutions/index.php/s/${res.data.ok}/download`;
                // console.log(link);
                sendToDevice("downloadMultimedia", `{ "link": "${link}", "type": "image", "fileName": "${filesContent[0].name}" }`);
                setStatusSendFile("Файл доступен Sunny");
            }
        }
    }

    useEffect(() => {
        if (!(socket as unknown as Socket).connected) {
            console.log("НОВЫЙ СОКЕТ!");
            // socket = io("http://localhost:5001");
            // socket = io(process.env.REACT_APP_API_URL + ":" + process.env.REACT_APP_API_WEBSOCKET_PORT);
            socket = io(process.env.REACT_APP_API_URL + "/commands");
            socket.auth = { access_token: localStorage.getItem('accessToken') };

            socket.on('connect', () => {
                console.log("Connect");

            });

            socket.on('disconnect', () => {
                //setIsConnected(false);
                console.log("Disconnect");
            });

            socket.on('error', async (payload: { type: string, message: string }) => {
                //setIsConnected(false);
                console.log(payload);

                if (payload.type && payload.type == "auth" && payload.message && payload.message == "token_expired") {
                    console.log("REFRESH");

                    // AuthService.refresh();
                    await dispatch(doRefreshTokens());
                    socket = io(process.env.REACT_APP_API_URL + "/commands");
                    socket.auth = { access_token: localStorage.getItem('accessToken') };

                }
            });

            socket.on('sensorsState', (payload: string) => {
                //setIsConnected(false);
                console.log("sensorsState: " + payload);
                var jsonPayload = JSON.parse(payload);
                dispatch(setSensorsState(jsonPayload));
            });

            // socket.on('msgToUser', (payload: SetDeviceSensor) => {
            //     console.log(payload.ident + " " + payload.sensorName + " " + payload.sensorValue);
            //     dispatch(setDeviceSensor({ ident: payload.ident, sensorName: payload.sensorName, sensorValue: payload.sensorValue }));
            // });
        }
        return () => {
            socket.off('connect');
            socket.off('disconnect');
            socket.off('msgToUser');
            socket.close();
        };
    }, [dispatch]);

    useEffect(() => {
        if (device) {
            dispatch(setIdent(device.ident));
        }
    }, [dispatch, device]);

    useEffect(() => {
        if (device?.ident) {
            console.log("useEffect ident: " + device?.ident);
            socket.emit("subscribeSensors", device?.ident);
            //socket.emit("sendToDevice", device?.ident);
        }
        return () => {
            if (device) {
                dispatch(setIdent(""));
                dispatch(clearDeviceDetailsState())
            }
        }
    }, [dispatch, device])

    const setAxisValue = (axisType: string, newValue: number) => {
        //console.log("setAxisValue " + `{ "${sensorName}": ${strValue} }`);
        var axis = "vertical";
        switch (axisType) {
            case DeviceSensors.AXIS_DISPLAY:
                axis = "display";
                break;
            case DeviceSensors.AXIS_VERTICAL:
                axis = "vertical";
                break;
            case DeviceSensors.AXIS_HORIZONTAL:
                axis = "horizontal";
                break;
            default:
                break;
        }
        dispatch(sensorLoading(axisType));
        sendToDevice("setAxisValue", `{ "axis": "${axis}", "angle": ${newValue} }`);
    }

    const setSensorValue = (sensorName: string, newValue: any) => {
        const strValue = typeof newValue === "string" ? `"${newValue}"` : `${newValue}`;
        //console.log("setSensorValue " + `{ "${sensorName}": ${strValue} }`);
        dispatch(sensorLoading(sensorName));
        sendToDevice("setSensorValue", `{ "key": "${sensorName}", "value": ${strValue} }`);
    }

    const setConfigDeviceValue = (type: string, newValue: any, type_send?: string, additionalParams?: any) => {
        const strValue = typeof newValue === "string" ? `"${newValue}"` : `${newValue}`;
        additionalParams = additionalParams ? ", " + additionalParams : "";
        //console.log("setSensorValue " + `{ "${sensorName}": ${strValue} }`);
        dispatch(sensorLoading(type));
        type_send = type_send ? type_send : type;
        sendToDevice("setConfigDevice", `{ "type": "${type_send}", "value": ${strValue} ${additionalParams} }`);
    }

    const sendFaceEmotion = (typeEmotion: string) => {
        sendToDevice("setFaceEmotion", `{"emotion": "${typeEmotion}", "duration": 5000}`);
    }

    const sendLedNotify = (typeNotify: string) => {
        sendToDevice("setLedNotify", `{"notify": "${typeNotify}"}`);
    }

    const sendFireLed = (ledNumber: string, ledColor: string) => {
        sendToDevice("setFireLed", `{"address": ${ledNumber}, "rgb": "${ledColor}"}`);
    }

    const sendCalibration = (axis: string) => {
        sendToDevice("doCalibration", `{"axis": "${axis}"}`);
    }

    const sendUpdateFW = (url: string) => {
        sendToDevice("doUpdateFW", `{"url": "${url}"}`);
    }

    const sendTextToSay = (text: string) => {
        sendToDevice("sayText", `{"text": "${text}"}`);
    }

    const sendTestMode = (enable: boolean) => {
        sendToDevice("testMode", `{"enable": ${enable}}`);
    }

    const sendToDevice = (msgType: string, payload: string) => {
        const msgToDevice: MsgToDevice = {
            ident: device?.ident,
            msgType: msgType,
            payload: JSON.parse(payload)
        }
        console.log(JSON.stringify(msgToDevice));

        socket.emit("msgToDevice", JSON.stringify(msgToDevice));
    }

    //console.log(deviceSensors.sensors.stateLaser);

    const timerRef = useRef(setTimeout(() => { }, 100));

    const handleChangeAxisSlider = (typeSensor: string, value: number) => {
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            setAxisValue(typeSensor, value);
        }, 500);
    }

    // const handleChangeSensorSlider = (typeSensor: string, value: number) => {
    //     clearTimeout(timerRef.current);
    //     timerRef.current = setTimeout(() => {
    //         setSensorValue(typeSensor, value);
    //     }, 500);
    // }

    const handleChangeConfigDeviceSlider = (type: string, value: number, type_send?: string, additionalParams?: any) => {
        type_send = type_send ? type_send : type;
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            setConfigDeviceValue(type, value, type_send, additionalParams);
        }, 500);
    }

    const handleChangeTextToSay = (event: any) => {
        // const result = event.target.value.replace(/[^a-z]/gi, '');
        const result = event.target.value.replace(/[^a-zA-Z0-9 .,!?]/, '');

        setTextToSay(result);
    };

    const handleChangeLedNumber = (event: any) => {
        const result = event.target.value.replace(/[^0-9]/, '');

        setLedNumber(result);
    };

    const handleChangeLedColor = (event: any) => {
        const result: string = event.target.value.replace(/[^0-9A-Fa-f]/, '');

        setLedColor(result.substring(0, 6).toUpperCase());
    };

    return (
        <div className="page-device-remote">
            <p>idDevice: <b>{device?.id}</b>,
                ident: <b>{device?.ident}</b></p>
            <Button
                onClick={() => setShowVideo(!showVideo)}>
                {showVideo ? "Hide video chat" : "Show video chat"}</Button>
            <p>
                {showVideo &&
                    <iframe
                        title="remote-chat"
                        style={{ width: 600, height: 400 }}
                        allow="camera;microphone"
                        id="aaaaaa" name="aaaa"
                        src="https://apiv1.sunny.solutions/video-chat">
                    </iframe>
                }
            </p>

            {/* <p>ident : {deviceSensors.ident}</p>
            <p>laser_on: {deviceSensors.sensors.laser_on?.value + ""}</p>
            <p>flashlight_on: {deviceSensors.sensors.flashlight_on?.value + ""}</p>
            <p>axis_x: {deviceSensors.sensors.axis_x?.value}</p>
            <p>axis_y: {deviceSensors.sensors.axis_y?.value}</p>
            <p>axis_z: {deviceSensors.sensors.axis_z?.value}</p>
            <p>volume: {deviceSensors.sensors.volume_music?.value}</p>
            <p>brightness: {deviceSensors.sensors.brightness?.value}</p> */}
            <span>Display: {deviceSensors.sensorsLocal.display_local}</span>
            <SingleRangeSlider
                id='slider_axis_display'
                value={deviceSensors.sensorsLocal.display_local}
                defaultMinValue={-1 * axis_display_zero}
                defaultMaxValue={axis_display_full - axis_display_zero}
                step={axis_display_step}
                color='orange'
                onChange={(newValue) => {
                    dispatch(setLocalAxisDisplay(newValue));
                    handleChangeAxisSlider(DeviceSensors.AXIS_DISPLAY, newValue)
                }}
                disabled={deviceSensors.sensors.display?.loading}
                className="sunny-axis-slider"
            />
            <Button style={{ marginTop: '20px' }}
                onClick={() => {
                    // const defaultAngle = 577.5 * axis_x_step;
                    const defaultAngle = 0;
                    dispatch(setLocalAxisDisplay(defaultAngle));
                    handleChangeAxisSlider(DeviceSensors.AXIS_DISPLAY, defaultAngle);
                }}
            >Default position</Button>

            <div style={{ marginTop: '20px' }}>Vertical: {deviceSensors.sensorsLocal.vertical_local}</div>
            <SingleRangeSlider
                id='slider_axis_y'
                value={deviceSensors.sensorsLocal.vertical_local}
                defaultMinValue={-1 * axis_vertical_zero}
                defaultMaxValue={axis_vertical_full - axis_vertical_zero}
                step={axis_vertical_step}
                color='orange'
                onChange={(newValue) => {
                    dispatch(setLocalAxisVertical(newValue));
                    handleChangeAxisSlider(DeviceSensors.AXIS_VERTICAL, newValue)
                }}
                disabled={deviceSensors.sensors.vertical?.loading}
                className="sunny-axis-slider"
            />
            <Button style={{ marginTop: '20px' }}
                onClick={() => {
                    // const defaultAngle = 422.22 * axis_y_step;
                    const defaultAngle = 0;
                    dispatch(setLocalAxisVertical(defaultAngle));
                    handleChangeAxisSlider(DeviceSensors.AXIS_VERTICAL, defaultAngle);
                }}
            >Default position</Button>

            <div style={{ marginTop: '20px' }}>Horizontal: {deviceSensors.sensorsLocal.horizontal_local}</div>
            <SingleRangeSlider
                id='slider_axis_z'
                value={deviceSensors.sensorsLocal.horizontal_local}
                defaultMinValue={-1 * axis_horizontal_zero}
                defaultMaxValue={axis_horizontal_full - axis_horizontal_zero}
                step={axis_horizontal_step}
                color='orange'
                onChange={(newValue) => {
                    dispatch(setLocalAxisHorizontal(newValue));
                    handleChangeAxisSlider(DeviceSensors.AXIS_HORIZONTAL, newValue)
                }}
                disabled={deviceSensors.sensors.horizontal?.loading}
                className="sunny-axis-slider"
            />
            <Button style={{ marginTop: '20px' }}
                onClick={() => {
                    const defaultAngle = 0;
                    dispatch(setLocalAxisHorizontal(defaultAngle));
                    handleChangeAxisSlider(DeviceSensors.AXIS_HORIZONTAL, defaultAngle);
                }}
            >Default position</Button>

            <div style={{ marginTop: '20px' }}>volume: {deviceSensors.sensorsLocal.volume_music_local}</div>
            <SingleRangeSlider
                id='slider_volume_music'
                value={deviceSensors.sensorsLocal.volume_music_local}
                defaultMinValue={0}
                defaultMaxValue={deviceSensors.sensorsSettings.volume_music_max}
                // defaultMaxValue={16}
                step={1}
                color='orange'
                onChange={(newValue) => {
                    dispatch(setLocalVolumeMusic(newValue));
                    handleChangeConfigDeviceSlider(DeviceSensors.VOLUME_MUSIC, newValue, "volume", '"stream": "music"');
                    // handleChangeConfigDeviceSlider(DeviceSensors.VOLUME_MUSIC, newValue, '"stream": "music"');
                }}
                disabled={deviceSensors.sensors.volume_music?.loading}
                className="sunny-axis-slider"
            />
            <br />
            <span>brightness: {deviceSensors.sensorsLocal.brightness_local}</span>
            <SingleRangeSlider
                id='slider_brightness'
                value={deviceSensors.sensorsLocal.brightness_local}
                defaultMinValue={0}
                defaultMaxValue={deviceSensors.sensorsSettings.brightness_max}
                step={1}
                color='orange'
                onChange={(newValue) => {
                    dispatch(setLocalBrightness(newValue));
                    handleChangeConfigDeviceSlider(DeviceSensors.BRIGHTNESS, newValue);
                }}
                disabled={deviceSensors.sensors.brightness?.loading}
                className="sunny-axis-slider"
            />
            <br />

            <Button
                onClick={() => setSensorValue(DeviceSensors.LASER_ON, Number(!deviceSensors.sensors.laser_on?.value).toString())}
                className={deviceSensors.sensors.laser_on?.loading ? "loading disabled" : ""}
                color={deviceSensors.sensors.laser_on?.value ? "red" : "grey"}>
                LASER
            </Button>
            <Button
                onClick={() => setSensorValue(DeviceSensors.FLASHLIGHT_ON, Number(!deviceSensors.sensors.flashlight_on?.value).toString())}
                className={deviceSensors.sensors.flashlight_on?.loading ? "loading disabled" : ""}
                color={deviceSensors.sensors.flashlight_on?.value ? "red" : "grey"}>
                FLASHLIGHT
            </Button>

            <Header as="h3">Send face emotion</Header>
            <Button onClick={() => sendFaceEmotion("smile")}>Smile</Button>
            <Button onClick={() => sendFaceEmotion("sad")}>Sad</Button>
            <Button onClick={() => sendFaceEmotion("angry")}>Angry</Button>
            <Button onClick={() => sendFaceEmotion("cry")}>Cry</Button>
            {/* <Button onClick={() => sendFaceEmotion("laser")}>Laser</Button> */}
            <Button onClick={() => sendFaceEmotion("wait")}>Wait</Button>
            <Button onClick={() => sendFaceEmotion("sleep")}>Sleep</Button>
            {/* <Button onClick={() => sendFaceEmotion("talk")}>Talk</Button> */}
            <Button onClick={() => sendFaceEmotion("crazy")}>Crazy</Button>
            <Button onClick={() => sendFaceEmotion("heart")}>Heart</Button>

            <Header as="h3">LED Notify</Header>
            <Button onClick={() => sendLedNotify("off")}>Off</Button>
            <Button onClick={() => sendLedNotify("charging")}>Charging</Button>
            <Button onClick={() => sendLedNotify("low_power")}>Low power</Button>
            <Button onClick={() => sendLedNotify("charged")}>Charged</Button>
            <Button onClick={() => sendLedNotify("laser")}>Laser</Button>
            <Button onClick={() => sendLedNotify("camera")}>Camera</Button>
            <br /><br />
            <Button onClick={() => sendLedNotify("camera_mute")}>Camera mute</Button>
            <Button onClick={() => sendLedNotify("mic_mute")}>Microphone mute</Button>
            <Button onClick={() => sendLedNotify("music")}>Music playing</Button>
            <Button onClick={() => sendLedNotify("sleep")}>Sleep mode</Button>
            <br /><br />
            <Button onClick={() => sendLedNotify("left_console")}>Left Console</Button>
            <Button onClick={() => sendLedNotify("right_console")}>Right Console</Button>
            <Button onClick={() => sendLedNotify("middle_off")}>Middle OFF</Button>

            <Header as="h3">Fire LED</Header>
            <Input
                placeholder="LED number"
                value={ledNumber}
                onChange={handleChangeLedNumber} />
            <Input
                placeholder="Color"
                value={ledColor}
                onChange={handleChangeLedColor} />
            <Button
                onClick={() => sendFireLed(ledNumber, ledColor)}
                className={ledNumber.length === 0 || ledColor.length !== 6 ? "disabled" : ""}>
                Send Fire LED
            </Button>

            <Header as="h3">Say text</Header>
            <Input
                placeholder="Text to say..."
                value={textToSay}
                onChange={handleChangeTextToSay} />
            <Button
                onClick={() => sendTextToSay(textToSay)}
                className={textToSay.length === 0 ? "disabled" : ""}>
                Say text
            </Button>

            <Header as="h3">Show picture</Header>
            <Input
                placeholder="Select picture"
                // disabled
                value={filesContent.length > 0 ? filesContent[0].name : ""} />
            <Button
                onClick={() => openFileSelector()}>
                Select file
            </Button>
            <Button
                disabled={filesContent.length === 0}
                onClick={() => sendFile()}>
                Send file
            </Button>
            <span>{statusSendFile}</span>

            <Header as="h3">Calibration</Header>
            <Button
                onClick={() => sendCalibration("vertical")}>
                Vertical
            </Button>
            <Button
                onClick={() => sendCalibration("horizontal")}>
                Horizontal
            </Button>
            <Button
                onClick={() => sendCalibration("display")}>
                Display
            </Button>

            <Header as="h3">Update Firmware</Header>
            <Button
                onClick={() => sendUpdateFW("")}>
                Update
            </Button>

            <Header as="h3">Test mode</Header>
            <Button
                onClick={() => sendTestMode(true)}>
                Test mode ON
            </Button>
            <Button
                onClick={() => sendTestMode(false)}>
                Test mode OFF
            </Button>
        </div >
    )
}
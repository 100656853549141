import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "../hooks";
import { setActivePage } from "../store/slices/ActivePageSlice";

export const ActivePageChanger = () => {
    const location = useLocation();

    const dispatch = useAppDispatch();

    useEffect(() => {
        const activePageId = (location.pathname.split("/").at(1))
            ? location.pathname.split("/").at(1) : "";
        dispatch(setActivePage(activePageId as unknown as string));
    }, [dispatch, location])

}
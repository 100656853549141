import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../http";


type Device = {
    id: number;
    ident: string;
    status: string;
    hardware_version: string;
}

type DevicesResponse = {
    list: Device[],
    page: number,
    pageQty: number,
}

type DevicesState = {
    list: Device[];
    page: number,
    pageQty: number,
    loading: boolean;
    error: string | null;
}

const initialState: DevicesState = {
    list: [],
    page: 1,
    pageQty: 1,
    loading: false,
    error: null
}

export const getDevices = createAsyncThunk<DevicesResponse, { page: number, qtyPerPage: number }, { rejectValue: string }>(
    'devices/getDevices',
    async ({ page, qtyPerPage }, { rejectWithValue }) => {
        const response = await api.get(`/devices/all?page=${page}&qtyPerPage=${qtyPerPage}`);

        if (response.status !== 200) {
            return rejectWithValue('Request ERROR');
        }

        return response.data;
    })

const devicesSlice = createSlice({
    name: 'devices',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDevices.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getDevices.fulfilled, (state, action) => {
                state.loading = false;
                state.list = action.payload.list;
                state.page = action.payload.page;
                state.pageQty = action.payload.pageQty;
            })
    }
})

export type { Device };

export const devicesReducer = devicesSlice.reducer;


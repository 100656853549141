import axios, { AxiosResponse, InternalAxiosRequestConfig } from "axios";

// export const API_URL = process.env.REACT_APP_API_URL + ":" + process.env.REACT_APP_API_PORT; //|| "http://localhost:5000";
export const API_URL = process.env.REACT_APP_API_URL + ""; //|| "http://localhost:5000";

const refreshTokenUrl = API_URL + "/refresh_tokens";

var failedQueue: any[] = [];
var isRefreshing = false;

const processQueue = (error: any) => {
    failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve();
        }
    });

    failedQueue = [];
};

const api = axios.create({

    withCredentials: false,
    baseURL: API_URL,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
    }

})

api.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
        config.headers = config.headers ?? {};
        if (config.headers.Authorization !== false) {
            const token = localStorage.getItem('accessToken');

            if (token) {
                config.headers.Authorization = "Bearer " + token;
            }
        }
        return config;
    },
    (error) => {
        console.log(error);

        return Promise.reject(error);
    }
)

api.interceptors.response.use(
    (response: AxiosResponse) => {
        return response;
    }, async (error) => {
        // console.log(error);

        // console.log("response error " + error.response.status);
        // if (error.response.status === 401) {
        //     localStorage.setItem('accessToken', "");
        //     window.location.href = '/';
        // }

        // const originalRequest = error.config;
        // // In "axios": "^1.1.3" there is an issue with headers, and this is the workaround.
        // originalRequest.headers = JSON.parse(
        //     JSON.stringify(originalRequest.headers || {})
        // );
        // console.log(originalRequest.headers);

        // const refreshToken = localStorage.getItem('refreshToken');

        // // If error, process all the requests in the queue and logout the user.
        // const handleError = (error: any) => {
        //     processQueue(error);
        //     // logout();
        //     return Promise.reject(error);
        // };

        const originalRequest = error.config;
        // In "axios": "^1.1.3" there is an issue with headers, and this is the workaround.
        originalRequest.headers = JSON.parse(
            JSON.stringify(originalRequest.headers || {})
        );
        const refreshToken = localStorage.getItem('refreshToken');

        // If error, process all the requests in the queue and logout the user.
        const handleError = (error: any) => {
            processQueue(error);
            // logout();
            return Promise.reject(error);
        };

        // console.log(error.response.data.error);
        // Refresh token conditions
        if (
            refreshToken &&
            error.response?.status === 401 &&
            error.response.data.error === "token_expired" &&
            originalRequest?.url !== refreshTokenUrl &&
            originalRequest?._retry !== true
        ) {

            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({ resolve, reject });
                })
                    .then(() => {
                        return api.request(originalRequest);
                    })
                    .catch((err) => {
                        return Promise.reject(err);
                    });
            }
            isRefreshing = true;
            originalRequest._retry = true;
            return api
                .post(refreshTokenUrl, {
                    refreshToken: refreshToken,
                })
                .then((res) => {
                    const tokens = {
                        accessToken: res.data?.accessToken,
                        refreshToken: res.data?.refreshToken,
                    };
                    localStorage.setItem('accessToken', tokens.accessToken);
                    localStorage.setItem('refreshToken', tokens.refreshToken);
                    // setRefreshedTokens(tokens);
                    processQueue(null);

                    return api.request(originalRequest);
                }, handleError)
                .finally(() => {
                    isRefreshing = false;
                });
        }

        // Refresh token missing or expired => logout user...
        if (
            (error.response?.status === 401 || error.response?.status === 450) &&
            (error.response?.data?.error === "token_expired" || error.response?.data?.error === "token_invalid"
                || error.response?.data?.error === "token_not_found" || error.response?.data?.error === "not_authorized")
        ) {
            localStorage.setItem('accessToken', "");
            localStorage.setItem('refreshToken', "");
            // const dispatch = useAppDispatch();
            // dispatch(setUserState({ accessToken: "", refreshToken: "" }));
            window.location.href = '/';
            return handleError(error);
        }

        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
    }
)

export default api;
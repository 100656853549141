import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks";
import { getDeviceDetails } from "../store/slices/DeviceDetailsSlice";

export const DeviceDetailsPage: React.FC = () => {
    const params = useParams();

    const dispatch = useAppDispatch();

    const device = useAppSelector(state => state.deviceDetails.device);

    useEffect(() => {
        dispatch(getDeviceDetails(params.idDevice as unknown as number))
    }, [dispatch, params])

    return (
        <div>
            <p>ID: {device?.id}</p>
            <p>Ident: {device?.ident}</p>
            <p>Hardware version: {device?.hardware_version}</p>
            <p>Software version: {device?.software_version}</p>
            <p>Type: {device?.type}</p>
            <p>Status: {device?.status}</p>
        </div>

    )
}
import { Container, List } from "semantic-ui-react";

const treeData = [
    {
        name: 'level1',
        flag: 'base',
        children: [
            { name: 'level2-1' },
            {
                name: 'level2-2',
                children: [
                    { name: 'level3-1', children: [{ name: 'level4-1', flag: 'table' }] },
                    { name: 'level3-2', flag: 'file' },
                    { name: 'level3-3', children: [{ name: 'level4-2', flag: 'table' }] },
                ]
            }
        ]
    },

    {
        name: 'long-name-long-name-long-name-long-name-long-name-',
        flag: 'base',
        children: [
            { name: 'level2-1' },
            {
                name: 'level2-2',
                children: [
                    { name: 'level3-1', flag: 'file' },
                    { name: 'level3-2', flag: 'file' }
                ]
            }
        ]
    }
];

const CompetenciesTree = () => {
    return (
        <Container className="table-devices">
            <List>
                <List.Item>
                    <List.Header>
                        src
                    </List.Header>
                    <List.Header>
                        src1
                    </List.Header>
                </List.Item>
            </List>
        </Container>
    )
}



export default CompetenciesTree;
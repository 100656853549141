import { useEffect, useState } from 'react';
import { Container, Icon, Menu, Table } from 'semantic-ui-react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getDevices } from '../../store/slices/DevicesSlice';
import DeviceRow from './DeviceRow';

const DevicesTable = () => {
    const devicesList = useAppSelector(state => state.devices.list);
    const pageQty = useAppSelector(state => state.devices.pageQty);
    const pageState = useAppSelector(state => state.devices.page);

    const dispatch = useAppDispatch();

    const [page, setPage] = useState(pageState);

    useEffect(() => {
        dispatch(getDevices({ page: page, qtyPerPage: 10 }))
    }, [dispatch, page])

    return (
        <Container className="table-devices">
            <Table celled unstackable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>ID</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell>HW version</Table.HeaderCell>
                        <Table.HeaderCell>Details</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {devicesList.map((device) => (
                        <DeviceRow key={device.ident} {...device} />
                    ))}

                </Table.Body>

                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan='4'>
                            <Menu floated='right' pagination>
                                {<Menu.Item as='a' icon onClick={() => setPage(page > 1 ? page - 1 : page)}>
                                    <Icon name='chevron left' />
                                </Menu.Item>}
                                {[...Array(pageQty)].map((x, i) => {
                                    const style = page === i + 1 ? { 'fontWeight': "bold" } : {};
                                    const className = (page === i + 1) ? "color-main" : "";
                                    return <Menu.Item key={"page" + i} as='a' className={className} style={style} onClick={() => setPage(i + 1)}>{i + 1}</Menu.Item>
                                })}
                                {<Menu.Item as='a' icon onClick={() => setPage(page < pageQty ? page + 1 : page)}>
                                    <Icon name='chevron right' />
                                </Menu.Item>}
                            </Menu>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>

        </Container >
    )
}

export default DevicesTable;